.langCol {
    text-align: end;
    display: flex;
    align-items: center;
    gap: 5px;
.red-dot-notification{
    content: "";
  position: absolute;
  right: 13px; 
  width: 5px;
  height: 5px;
  background-color: red;
  border-radius: 50%;
}
    span {
        font-size: 20px;
        font-weight: 200;
    }
}

.logo-sec {
    padding: 15px 20px 15px 15px;
}

.position-top {
    position: sticky;
    top: 0;
    z-index: 5;
}

/* Your existing styles for modal */
body  { 
    overflow: scroll !important;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1040;
    /* Adjust the z-index to be higher than the modal (usually modal z-index is 1030) */
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent black overlay */
    display: none;

}

.language-dropdown {
    position: relative;
}

.dropdown-trigger {
    cursor: pointer;
}

.dropdown-options {
    position: absolute;
    top: 70%;
    left: 75%;
    background-color: #fff;
    // border: 1px solid #ccc;
    border-top: none;
    width: 50px;

    /* Adjust the width as needed */
    ul {
        margin: 0px;

        li {
            padding: 5px 10px;
        }
    }
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.selectedLang {
    background-color: grey;
}
.tierLi{
li::before {
    // list-style-image: url('../../assets/images/gray-check.png');
    // content: "\2714";
    // color: #717171;
    // margin-left: -16px;
    // padding-right: 10px;
}

li {
    list-style: none;
  background: url('../../assets/images/gray-check.png') no-repeat;
  background-size: 15px 15px; /* adjust the size of the image */
  padding: 0px 5px 15px 25px;
  align-items: center;
    cursor: pointer;
    color: black;
}

li:hover {
    background-color: white;
}
}
li {
    padding: 5px;
    cursor: pointer;
    color: black;
}


.bg-pink {
    background: rgb(243, 123, 136);
    background: linear-gradient(90deg,
            rgba(243, 123, 136, 1) 0%,
            rgba(255, 255, 255, 1) 20%,
            rgba(255, 255, 255, 1) 28%,
            rgba(255, 255, 255, 1) 73%,
            rgba(250, 216, 219, 1) 85%,
            rgba(243, 123, 136, 1) 100%);
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.bg-gratGradient {

    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 1rem;
    @media (min-width: 620px) {
        padding: 1.5rem;
      }
      @media (min-width: 950px) {
        padding: 2rem;
      }
    .text-right {
        text-align: right;
    }

    .tier-text-area {
        // padding-top: 20px;

        h3 {
            font-size: 16px;
            font-weight: bold;
            color: #0d0d0d;
            margin-bottom: 0;
            @media (min-width: 620px) {
                font-size: 20px;
              }
              @media (min-width: 950px) {
                font-size: 25px;
              }
        }

        p {
            font-size: 16px;
            @media (min-width: 620px) {
                font-size: 20px;
              }
              @media (min-width: 950px) {
                font-size: 25px;
              }
        }

        .lastpara {
            font-size: 10px;
            @media (min-width: 620px) {
                font-size: 13px;
              }
              @media (min-width: 950px) {
                font-size: 15px;
              }
        }
        .justifyEnd{
            justify-content: flex-end !important;
        }
    }

    .qr-sec {
        padding: 10px;
        background: #fff;
        border-radius: 50%;
        cursor: pointer;

        .modal {
            $modal-fade-transform: translate(-100px 100px);
            text-align: center;

            img {
                width: 80px;

            }

            .qr-code-digit {
                font-size: 16px;
                padding: 5px 0px 20px 0px;
            }

            .qr-code-time {
                font-size: 10px;
                color: #717171;
            }

            .modal-footer {
                justify-content: space-between;
                padding: 5px 0;
                background: #bcbcbc;

                img {
                    width: 47px;
                    margin-top: -30px;
                    z-index: 10;
                }

                h6 {
                    font-size: 14px;
                    color: #000;
                    padding-left: 15px;
                    font-weight: 100;
                }
            }
        }

        .modal.fade.in .modal-dialog {
            padding: 10px;
        }
    }

}

// .morning-text {
//     p {
//         font-size: 18px;
//         font-weight: bold;
//         color: #fff;
//     }

//     button {
//         background-color: #da2128;
//         border: 1px solid #da2128;
//         border-radius: 4px;
//         padding: 5px 20px;
//         font-size: 12px;
//     }
// }

.langCol {
    font-size: 18px;
}

.dashboard {
    padding-top: 0px;

    .cool-section {
        border-radius: 5px;
        // padding: 15px;

        .slabcard-content {
            padding: 20px;
        }

        .img-text-area {
            h3 {
                font-size: 18px;
                @media (max-width: 450px) {
                    font-size: 16px;
                  }
                  @media (max-width: 400px) {
                    font-size: 14px;
                  }
                //   @media (min-width: 950px) {
                //     padding: 2rem;
                //   }
            }

            p {
                font-size: 14px;
                @media (max-width: 450px) {
                    font-size: 12px;
                  }
                  @media (max-width: 400px) {
                    font-size: 10px;
                  }
            }

            button {
                font-size: 12px;
                padding: 5px 15px;
                background-color: #000;
                &.bg-white-btn {
                    background-color: #FFF;
                    color: #000;
                }
            }

            img {
                max-width: 200px;
            }
        }
    }

    .seeMoreTierseeMoreTier {
        color: #fff;
        padding-top: 10px;
        display: block;
        font-size: 14px;

        span {
            cursor: pointer;
            text-decoration: underline;
        }
    }

    .close-sec {
        padding-bottom: 10px;
        text-align: right;

        img {
            cursor: pointer;
        }
    }
}

.bold-heading-h5 {
    padding: 10px 0;
}

// modal bottom stat
// .modal-dialog,
// .come-from-modal.right .modal-dialog {
//   height: 100%;
// }

// .come-from-modal.right .modal-content {
//   height: 100%;
// }

// .come-from-modal.right.fade .modal-dialog {
//   bottom: -320px;
//   transition: opacity 0.3s linear, right 0.3s ease-out;
// }

// 
.rewards {
    text-align: center;

    p {
        font-size: 12px;
        color: #fff;
    }
}


.dif-bg {
    background-size: 100% 100%;
    padding-bottom: 10px;


    .boldhHeadingH5 {
        font-size: 18px !important;
        padding-bottom: 2px !important;
    }

    p {
        padding-bottom: 20px;
        color: #fff;
        font-size: 14px;
    }

    .image-box {
        text-align: center;
    }

    .image-box-1 {
        padding: 5px;
    }

    .image-box-2 {
        padding-left: 5px;
    }

    .margin-box-1 {
        margin-left: 5px;
    }

    .image-box img {
        padding-bottom: 10px;
    }
}

.new-arrival {
    background-color: #f2f2f2;
    padding-bottom: 20px;

    h5 {
        color: #000;
    }
    .Reward-section .image-box .img-box-text{
        min-height: auto;
    }
}

.exclusive-box section.sections {
    padding-bottom: 10px;
}

.exclusive-section {
    padding: 20px 0;
    padding-bottom: 30px;
}

.exclusive-box {
    .item-navigation {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 5px;

        a {
            text-decoration: none;
            color: var(--bs-body-color);

            span {
                padding-left: 5px;
            }
        }

        img {
            width: 7px;
            opacity: 0.7;

            &:hover {
                opacity: 1;
            }
        }
    }
}

.heading_main-home {
    font-size: 18px;
    font-weight: 600;
    padding-top: 20px;
    padding-bottom: 15px;
}

.tiertag {
    min-height: 20px;
}


.editsize {
    background: #000000;
}


.Reward-section {
    text-align: left;

    .image-box {

        .points {
            font-size: 12px;

            .categoryName {
                border-radius: 12px;
                border: 0.5px solid #c1c1c1;
                padding: 0px 10px;
                font-size: 10px;
                margin-right: 5px;
                margin-left: 0px;
                margin-top: 10px;
            }

            .pointBold {
                font-weight: 600;
                font-size: 14px;
                padding: 0px 0px 5px;
                display: inline-block;
            }

            .greenfree {
                color: #0caa37;
                // font-weight: bold;
                font-size: 10px;
            }

            .freebox {
                border: 1px solid var(--lightgray);
                background-color: #D9F1E0;
                padding: 0px 7px;
                border-radius: 15px;
            }
        }

        .img-box-text {
            padding: 10px;
            padding-bottom: 10px;
            border: 1px solid var(--lightgray);
            border-radius: 0px 0px 4px 4px;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            max-height: 200px;
            overflow-y: scroll;
            min-height: 190px;
            .reward-text {
                // font-size: 12px;
                min-height: 50px;
                padding: 1px 0px 5px;
                font-size: 14px;
            }
        }

        .date {
            // color: $darkgray;
            font-size: 12px;
            padding-top: 10px;
            padding-bottom: 20;
        }
    }

    .img-box-text {
        min-height: 70px;
        border: 1px solid var(--lightgray);
    }

    .img-tag {
        position: relative;

        img {
            border-radius: 4px 4px 0px 0px;
            height: 100%;
            min-height: 11rem !important;
        }

        .rewards-label-btn {
            font-size: 10px;
            position: absolute;
            top: 5px;
            left: 15px;
            background-color: #4b4b4b;
            border: 1px solid #4b4b4b;
            color: #fff;
        }

        .enough_bal {
            button {
                top: 70%;
                font-size: 8px;
                background-color: #fff;
                color: #707070;
            }
        }
    }

    .reward {
        .title {
            font-size: 18px !important;
        }

        .points {
            color: #717171;
        }

        .pointBold {
            font-weight: bold;
            color: #000;
            font-size: 18px;
        }

        .reward-sec-col {
            cursor: pointer;
            width: 50%;
            padding: 5px;
            .img-box-text {
                min-height: 200px;
            }
        }

        .enoughSec {
            .img-tag {
                background-color: #000;

                img {
                    opacity: 0.7;
                }
            }
        }

        .boldhHeadingH5 {
            font-size: 14px;
            margin: 0;
            padding-bottom: 0px;
        }

        .reward_button_store button {
            border-radius: 12px;
            border: 0.5px solid #c1c1c1;
            padding: 0px 10px;
            font-size: 13px;
            margin-right: 5px;
            margin-left: 2px;
            margin-top: 5px;
        }

        .tier {
            padding: 10px 0px;
        }

        .store_channel button {
            font-size: 10px;
        }

        .highest {
            .tag{
                color: #2172da;
            }
            button {
                background-color: #2172da47;
                border: 0.5px solid #2172da47;
            }
        }

        .RewardImgwrap{
            position: relative;
            .thumbnailImgwrap{
                position: relative;
                width: 100%;
                &:after{
                    content: "";
                    display: block;
                    padding-bottom: 100%; 
                }
                img{
                    position: absolute; 
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0; 
                    width: 100%; 
                    height: 100%; 
                    object-fit: fill;
                    border-radius: 4px 4px 0px 0px; 
                }
            }
            button {
                font-size: 10px;
                position: absolute;
                top: 5px;
                left: 15px;
                background-color: #4b4b4b;
                border: 1px solid #4b4b4b;
                color: #fff;
            }
        }
    }
}

.referral-program {
    .cool-section {
        border-radius: 5px;
        padding: 15px;
    }

    .referral-text {
        h3 {
            font-size: 18px;
            font-weight: bold;
        }

        p {
            font-size: 14px;
            font-weight: var(--regular);
        }

        button {
            background-color: #fff;
            color: #000;
            font-size: 12px;
        }
    }

    .referral-text-points {
        // padding-bottom: 70px;
        @media (min-width: 480px) {
            // padding-bottom: 100px;
          }
          @media (min-width: 630px) {
            // padding-bottom: 150px;
          }
        h3 {
            font-size: 18px;
            font-weight: bold;
        }

        p {
            font-size: 14px;
            font-weight: var(--regular);
        }

        button {
            background-color: #fff;
            color: #000;
            font-size: 12px;
        }
    }

    .referal-program-img {
        width: 60%;
    }

    // h3 {
    //     font-size: 18px;
    //     font-family: bold;
    // }
    // p {
    //     font-size: 14px;
    // }
}

.referral-program-size {
    // margin-top: -45px;
    @media (min-width: 450px) {
        // margin-top: -65px;
      }
      @media (min-width: 550px) {
        // margin-top: -85px;
      }
    .cool-section {
        border-radius: 5px;
        padding: 15px;
    }

    .referral-text {
        h3 {
            font-size: 18px;
            font-weight: bold;
        }

        p {
            font-size: 14px;
            font-weight: var(--regular);
        }

        button {
            background-color: #fff;
            color: #000;
            font-size: 12px;
        }
    }

    

    .referal-program-img {
        width: 60%;
    }


}

footer {
    background-color: #292929;
    padding-top: 30px;
    margin-top: 40px;
    padding-bottom: 50px;

    .footer-text {
        color: #fff;
        font-size: 12px;
        padding-top: 20px;
    }

    h5 {
        font-weight: bold;
        font-size: 12px;
    }
}

.mesurement-img {
    background-size: cover;
    background-repeat: no-repeat;

    button {
        background: #000 !important;

        &:hover {
            background-color: #717171 !important;
        }
    }
}

.onpage-load {
    .modal {
        text-align: center;

        .modal-content {
            border-radius: 50% 50% 0 0;
        }

        .modal-header {
            img {
                filter: invert(1);
                cursor: pointer;
            }
        }

        .modal-body {
            padding-top: 50px;
            padding-bottom: 50px;
font-size: 22px;
            h1 {
                font-size: 38px;
                font-weight: bold;
                color: #000;
                margin: 0;
            }
            

            img {
                width: 100px;
            }

            .congrat-img {
                width: 38px;
            }

            .stoke-text {
                -webkit-text-stroke-width: 1px;
                -webkit-text-stroke-color: black;
                color: transparent;

            }
        }
    }
}

.custom-modal-dialog {
    // transform: translate(0, 224px) !important;
    margin: 0 !important;
    position: absolute !important;
    bottom: 0;

    .custom-close-btn {
        padding: 5px 10px;
    }

    .friend-box-outer_mainHome {
        .friend-box {
            padding: 0px 10px 0px 10px;
            font-size: small;

            .friend-text-first {
                ul{
                    padding-top: 15px;
                    padding-left: 0rem !important;
                }
                padding: 20px 0px 0px 0px;
            }

            .unlock_tier {
                font-size: 12px;
                color: #707070;
                font-weight: 100;

                a {
                    color: #707070;
                }
            }

            .carousel-indicators {
                bottom: -10px;

                button.active {
                    background-color: #717171;
                    border: none;
                    width: 12px;
                    height: 12px;
                }
            }

            .carousel-indicators [data-bs-target] {
                width: 10px;
                height: 10px;
                border-radius: 100%;
                border: 1px solid #707070;
            }
        }



        .memberShip_TierName {
            font-size: 22px;
            font-weight: var(--headingbold);
            padding: 10px 0;
            color: #000;
        }

        p {
            font-weight: bold;
            font-size: 16px;
            color: #000;
        }

        .list-ul_ {

    
            li {
                padding-bottom: 15px;
                font-size: 14px;
                color: #000;
            }
        }
    }
}

#exampleModal_currentMember {
    opacity: 1 !important;
    margin-bottom: 27px;
    border-radius: 4px 4px 0px 0px;

    .modal-content {
        position: fixed;
        top: auto;
        right: 0;
        left: 0;
        bottom: 0;
    }

    .modal-header {
        justify-content: end;
        border-bottom: 0px;
        padding: 10px;
    }

    .modal-dialog {
        transform: none;
    }

    .modal-text {
        color: #0D0D0D;
        font-size: 18px;
        font-weight: bold;
        text-align: center;
        margin-top: 43px;
    }

    .modal-subtext {
        color: #0D0D0D;
        font-size: 18px;
        text-align: center;
    }

    .popup-button {
        display: flex;
        justify-content: center;
        width: 100%;

        .modal-button1 {
            text-align: center;
            margin-right: 10px;

            button {
                padding: 12px 60px;
                margin-bottom: 26px;
                margin-top: 30px;
                background-color: #E4E4E4;
                border: 1px solid #E4E4E4;
                color: #000000;
                width: 100%;
                border-radius: 4px;
            }

            button:hover {
                background-color: #D1D1D1;
                border: 1px solid #D1D1D1;
            }
        }

        .modal-button2 {
            text-align: center;
            margin-right: 10px;

            button {
                padding: 12px 60px;
                margin-bottom: 26px;
                margin-top: 30px;
                background-color: #000000;
                border: 1px solid #000000;
                color: #FFFFFF;
                border-radius: 4px;
                width: 100%;
            }

            button:hover {
                background-color: #707070;
                border: 1px solid #707070;
            }
        }
    }

    .seeAll {
        text-align: center;
        color: #4B4B4B;
        font-weight: var(--semibold);
    }
}

.react-silder {

    &.news_silder {
        padding-bottom: 20px;
    }

    .react-multi-carousel-list {
        padding-bottom: 20px;
    }

    .react-multi-carousel-dot-list {
        background-color: #EAEAEA;
        width: min-content;
        border-radius: 4px;
        margin: 0px auto;

        li {
            padding: 0px;
        }
    }

    .react-multi-carousel-dot {
        display: flex;
    }

    .react-multi-carousel-dot button {
        background: transparent !important;
        border-color: transparent !important;
        width: 30px;
        height: 6px;
        margin-right: 0px;
        border-radius: 10%;
    }

    .react-multi-carousel-dot--active button {
        border-radius: 5px;
        width: 20px;
        height: 6px;
        border-color: #B5B5B5;
        background-color: #B5B5B5 !important;

    }

    .react-multiple-carousel__arrow {
        z-index: 2;
    }

    .image-box {
        background-color: #fff
    }
}

.news_silder {

    .image-box {
        border: 0px !important;
        box-shadow: 0px 3px 6px -3px #00000019;

        img {
            border-radius: 4px
        }
    }
}

.sign-in_btn {
    background-color: #DA2128 !important;
    border: 1px solid #DA2128 !important;
    border-radius: 4px !important;
    padding: 5px 20px !important;
    font-size: 12px !important;
    margin-right: 15px !important;
    height: fit-content !important;
}

.morning-text {
    p {
        font-size: 18px;
        font-weight: bold;
        color: #fff;
    }

    button {
        background-color: #da2128;
        border: 1px solid #da2128;
        border-radius: 4px;
        padding: 5px 20px;
        font-size: 12px;
    }
}
.get_it_now{
    // padding-top: 50px;
    // @media (max-width: 400px) {
    //     padding-top: 1vw;
    //   }
    //   @media (min-width: 600px) {
    //     padding-top: 25vw;
    //   }
    display: flex;
    flex-direction: column-reverse;

}

.newsPromotion{
    li{
        padding-left: 0px;
    }
}
.arrival-img-silder{
    li{
        padding-left: 0px;
    }
}
.check_in {
    background: #000000;
    // background-image: url('${homepageuicontrolData[0]?.sizechangebgimage}');
    background-size: "contain";
    background-position: "center center";
    width: "100%";
    height: "173px";
    background-color: "white";
    align-items:"end !important";
}
.main-home-popup
{
    .modal-footer,
    .modal-header {
        border: none;
    }

    .modal-dialog {
        padding: 36px
    }

    .modal {
        h5 {
            font-size: 22px;
            font-weight: 600;
        }
    }

    .text-black {
        color: #000
    }
    .referral-text {
        h3 {
            font-size: 18px;
            font-weight: bold;
        }

        p {
            font-size: 14px;
            font-weight: var(--regular);
        }

        button {
            background-color: black;
            color: #000;
            font-size: 12px;
        }
    }
}
.complete-profile-mainhome{
    width:60%;
    @media (max-width: 370px) {
        width:70%;
      }
      @media (max-width: 320px) {
        width:80%;
      }
}

#exampleModalCenter1{
    opacity: 1 !important;
    padding: 0px 35px;
    margin-bottom: 27px;
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    .modal-header{
        justify-content: end;
        border-bottom: 0px;
    }
    .modal-text{
        color: #0D0D0D;
        font-size: 22px;
        // font-weight: bold;
        text-align: center;
    }
    
    .modal-button{
        text-align: center;
       
        button{
            padding: 11px 11px;
            margin-bottom: 8px;
            margin-top: 28px;
            background-color: #000000;
            width: 200px;
        }
    }
    .modal-foottext{
        cursor: pointer;
        color: #000000;
        font-size: 18px;
        text-align: center;
        margin-bottom: 27px;
    }
}
body .modal-open{
    overflow: scroll !important;
}
// .react-multi-carousel-track {    overflow-y: scroll !important; } 