.complete-profile{
    margin: auto;
    width: 100%;
    overflow: hidden !important;
    .container-padding{
        padding: 0px 15px;
    }
    .toptext{
        font-size: 18px;
        color: #0D0D0D;
        font-weight: bold;
        margin-top: 14px;
        span{
            color: #707070;
            font-size: 14px;
            font-weight: normal;
        }
    }
    label{
        padding-bottom: 4px;
        font-size: 14px;
        color: #0D0D0D;
    }
    .input-field1{
        padding-top: 12px;
    }
    .input-field{
        padding-top: 20px;
    }
    input{
        width: 100%;
        height: 50px;
        padding: 15px 10px;
        font-size: 14px;
        color: #000000;
        border:solid #C1C1C1 1px;
        border-radius: 4px;
    }
    select{
        width: 100%;
        height: 50px;
        padding: 15px 10px;
        font-size: 14px;
        color: #000000;
        border:solid #C1C1C1 1px;
        border-radius: 4px;
    }
    .mobile{
        display: flex;
        select{
            width:30% ;
            border-radius: 4px 0px 0px 4px;
            background-color: #F2F2F2;
        }
        input{
            width: 70%;
            border-radius: 0px 4px 4px 0px;
            border-left: 0px;
            background-color: #F2F2F2;
        }
    }
    .moblabel{
        display: flex;
        justify-content: space-between;
        label{
            text-align: left;
        }
        .labelsub{
        padding-bottom: 4px;
        font-size: 12px;
        text-align: right;
        color: #707070;
        }
    }
    .radiobutton {
        display: flex;
        align-items: center;
    
        input {
          height: 15px;
          margin: 0;
        }
    
        .radiolabel {
          font-size: 14px;
          color: #000000;
          margin-left: 5px;
          white-space: nowrap; 
        }
      }
    .doblabel{
        display: flex;
        justify-content: space-between;
        label{
            text-align: left;
        }
        .labelsub{
        padding-bottom: 4px;
        font-size: 12px;
        text-align: right;
        color: #707070;
        }
    }
    .savebutton{
        
        width: 100%;
        position: fixed;
        bottom: 0;
        left: 0;
        margin-bottom: 20px;
        padding: 0px 15px;
        button{
            font-size: 18px;
            height: 50px;
            background-color: #000000;
            border-radius: 4px;
            color: #ffffff;
            width: 100%;
        }
    }
    .savebutton :hover{
        background-color: #707070;
        border: 1px solid ;
    }
    #exampleModalCenter{
        opacity: 1 !important;
        padding: 0px 35px;
        margin-bottom: 27px;
        border: 1px solid #FFFFFF;
        border-radius: 4px;
        .modal-header{
            cursor: pointer;
            justify-content: end;
            border-bottom: 0px;
            padding: 15px 15px;
        }
        .modal-text{
            color: #000000;
            font-size: 22px;
            font-weight: bold;
            text-align: center;
        }
        .modal-subtext{
            color: #000000;
            font-size: 22px;
            text-align: center;
        }
        .modal-button{
            text-align: center;
            button{
                background-color: #000000;
                padding: 11px 27px;
                margin-top: 28px;
                margin-bottom: 8px;
            }
        }
        .modal-foottext{
            color: #707070;
            font-size: 18px;
            text-align: center;
            margin-bottom: 27px;
        }
    }
}