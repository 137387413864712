#exampleModalCenter1{
    opacity: 1 !important;
    padding: 0px 35px;
    margin-bottom: 27px;
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    .modal-header{
        justify-content: end;
        border-bottom: 0px;
    }
    .modal-text{
        color: #0D0D0D;
        font-size: 22px;
        // font-weight: bold;
        text-align: center;
    }
    
    .modal-button{
        text-align: center;
       
        button{
            padding: 11px 11px;
            margin-bottom: 8px;
            margin-top: 28px;
            background-color: #000000;
            width: 200px;
        }
    }
    .modal-foottext{
        cursor: pointer;
        color: #000000;
        font-size: 18px;
        text-align: center;
        margin-bottom: 27px;
    }
}