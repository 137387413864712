.log-container {
  margin: auto;
  padding: 0px 15px;
  width: 100%;
  align-items: center;
  justify-content: center;
  .iti {
    &--allow-dropdown,
    &--show-selected-dial-code,
    &--show-flags,
    &--inline-dropdown {
      width: 100%;
    }
  }
  .ReactFlagsSelect-module_filterBox__3m8EU input {
    /* Your custom styles here */
    margin: 0px 0px;
    /* Add any other styles as needed */
  }
  .ReactFlagsSelect-module_selectOptionsWithSearch__1W03w{
    width:92vw;
    max-height: 200px;
  }
  .ReactFlagsSelect-module_selectBtn__19wW7{
    height:50px
  }
  .ReactFlagsSelect-module_filterBox__3m8EU {
    padding-top: 0px;
  }
  .ReactFlagsSelect-module_selectOptions__3LNBJ{
    margin-top: 0px;
  }
  /* Hide the country name in the dropdown */
  .iti__flag-container {
    display: flex;
    align-items: center;
  }
  
  .iti__flag {
    margin-right: 5px; /* Adjust spacing between flag and dial code */
  }
  
  .iti__selected-dial-code {
    font-weight: bold;
  }
  .error-message{
    font-size: 12px;
    color: #CC0000;
  }
  .countryCode{
    width: 100%;
    height: 50px;
    padding: 15px 10px;
    font-size: 14px;
    color: #000000;
    border: solid #C1C1C1 1px;
    border-radius: 4px;
  }
  label {
    padding: 10px 0px 4px 0px;
    font-size: 14px;
    color: #0D0D0D;
  }

  input {
    width: 100%;
    height: 50px;
    padding: 15px 10px;
    font-size: 14px;
    color: #000000;
    border: solid #C1C1C1 1px;
    border-radius: 4px;
  }

  select {
    width: 100%;
    height: 50px;
    padding: 15px 10px;
    font-size: 14px;
    color: #000000;
    border: solid #C1C1C1 1px;
    border-radius: 4px;
  }

  .mobile {
    display: flex;

    select {
      width: 30%;
      border-radius: 4px 0px 0px 4px;
    }

    input {
      width: 100%;
      border-radius: 0px 4px 4px 0px;
      // border-left: 0px;
    }
  }

  .moblabel {
    display: flex;
    justify-content: space-between;

    label {
      text-align: left;
    }

    .labelsub {
      padding: 10px 0px 4px 0px;
      font-size: 12px;
      text-align: right;
      color: #707070;
    }
  }

  #password {
    font-size: 50px;
  }

  .eyeicon1 {
    position: absolute;
    right: 20px;
    bottom: 15px;
    cursor: pointer;
  }

  .savebutton {

    width: 100%;
    margin-top: 23px;

    button {
      font-size: 18px;
      height: 50px;
      background-color: #000000;
      border-radius: 4px;
      color: #ffffff;
      width: 100%;
      border: 0px;
    }

    button:hover {
      background-color: #717171;
      border: 1px solid #717171;
    }
  }

  .forgot-label {
    padding-top: 5px;
    font-size: 14px;
    color: #2172DA;
    text-decoration: underline;
  }

  .alternative {
    text-align: center;
    margin-top: 20px;
    font-size: 14px;
  }
}