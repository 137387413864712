.earnprogress {
    .img-section {
        width: 100%;

        img {
            border: 1px solid #ccc;
        }
        .special{
            width: 90px;
            padding: 3px 20px;
            text-align: center;
            font-size: 14px;
            color: #FFFFFF;
            background-color: #4B4B4B;
            border: 1px solid #4B4B4B;
            border-radius: 14px;
            position: absolute;
            top: 80px;
            left: 15px;
        }
    }

    .challenger-box {
        .heading{
            margin-top: 0px;
            display: flex;
            justify-content: space-between; // Aligns items to each end of the container
  align-items: center;
            h5 {
                font-size: 16px;
                color: #0D0D0D;
              
                margin: 0;
            }
            .left-item{
                font-size: 14px;
                color: #717171;
               
                padding-top: 8px;
                margin: 0;
            }
            
        }
        .GQ-Coin{
            width: 15px;
            height: 15px;
            margin-bottom: 5px;
        }
        .store-button{
            padding-top: 3px;
            display: flex;
            align-items: center;
            .store-but1, .store-but2, .store-but3{
                width: 69px;
                height: 16px;
                margin-left: 4px;
                font-size: 10px;
                border: 0.5px solid #C1C1C1;
                border-radius: 9px;
                text-align: center;
            }
            .store-text{
                font-size: 14px;
                color: #0D0D0D;
                margin-right: 6px;
            }
          }
        .progress {
            border: 1px solid #707070;
            margin-bottom: 5px;
            height: 5.16px;
            background-color: #fff;
            margin-top: 5px;

            .progress-bar {
                background: var(--darkgray);
            }
        }

        .Ponit_status {
            p {
                font-size: 10px;
            }
        }

        .challenger-details {
            .pointBold {
                font-weight: var(--semibold);
            }
        }

        .howWork {
            padding: 20px 0;
            .title {
                font-weight: bold;
                font-size: 14px;
            }

            .description {
                font-size: 14px;
                color: #707070;
            }
        }
    }
    .savebutton-rewards{
        border-top: .25px solid #c1c1c1;
        background-color: white;
        z-index: 10;
        width: 100%;
        position: fixed;
        bottom: 0;
        left: 0;
        // margin-bottom: 20px;
        padding: 15px;
        .above-button{
            padding-bottom: 7px;
            font-size: 14px;
            color: #717171;
        }
        button{
            font-size: 18px;
            height: 50px;
            background-color: #DA2128;
            border: 1px solid #DA2128;
            border-radius: 4px;
            color: #ffffff;
            width: 100%;
        }
        .inactive_button{
            background-color: #959595;
            border: 1px solid #959595;
        }
        .redeem-img{
            margin-bottom: 4px;
        }
    }
    #exampleModalCenter{
        opacity: 1 !important;
        margin-bottom: 27px;
        border: 1px solid #FFFFFF;
        border-radius: 4px 4px 0px 0px;
        .modal-content{
            position:fixed;
            top:auto;
            right:0;
            left:0;
            bottom:0;
        }
        .modal-header{
            justify-content: end;
            border-bottom: 0px;
        }
        .modal-dialog{
            transform: none;
        }
        .modal-text{
            color: #0D0D0D;
            font-size: 18px;
            font-weight: bold;
            text-align: center;
            margin-top: 43px;
        }
        .modal-subtext{
            color: #0D0D0D;
            font-size: 18px;
            text-align: center;
        }
        .popup-button{
            display: flex;
            justify-content: center;
            width: 100%;
        .modal-button1{
            text-align: center;
           margin-right: 10px;
            button{
                padding: 12px 60px;
                margin-bottom: 26px;
                margin-top: 30px;
                background-color:#E4E4E4;
                border: 1px solid #E4E4E4;
                color: #000000;
                width: 100%;
                border-radius: 4px;
            }
            button:hover{
                background-color:#D1D1D1;
                border: 1px solid #D1D1D1;
            }
        }
        .modal-button2{
            text-align: center;
           margin-right: 10px;
            button{
                padding: 12px 60px ;
                margin-bottom: 26px;
                margin-top: 30px;
                background-color: #000000;
                border: 1px solid #000000;
                color: #FFFFFF;
                border-radius: 4px;
                width: 100%;
            }
            button:hover{
                background-color:#707070;
                border: 1px solid #707070;
            }
        }
    }
        
    }
     #exampleModalCenter{
        opacity: 1 !important;
        margin-bottom: 27px;
        border: 1px solid #FFFFFF;
        border-radius: 4px 4px 0px 0px;
        .modal-content{
            position:fixed;
            top:auto;
            right:0;
            left:0;
            bottom:0;
        }
        .modal-header{
            justify-content: end;
            border-bottom: 0px;
        }
        .modal-dialog{
            transform: none;
        }
        .modal-text{
            color: #0D0D0D;
            font-size: 18px;
            font-weight: bold;
            text-align: center;
            margin-top: 43px;
        }
        .modal-subtext{
            color: #0D0D0D;
            font-size: 18px;
            text-align: center;
        }
        .popup-button{
            display: flex;
            justify-content: center;
            width: 100%;
        .modal-button1{
            text-align: center;
           margin-right: 10px;
            button{
                padding: 12px 60px;
                margin-bottom: 26px;
                margin-top: 30px;
                background-color:#E4E4E4;
                border: 1px solid #E4E4E4;
                color: #000000;
                width: 100%;
                border-radius: 4px;
            }
            button:hover{
                background-color:#D1D1D1;
                border: 1px solid #D1D1D1;
            }
        }
        .modal-button2{
            text-align: center;
           margin-right: 10px;
            button{
                padding: 12px 60px ;
                margin-bottom: 26px;
                margin-top: 30px;
                background-color: #000000;
                border: 1px solid #000000;
                color: #FFFFFF;
                border-radius: 4px;
                width: 100%;
            }
            button:hover{
                background-color:#707070;
                border: 1px solid #707070;
            }
        }
    }
        
    }
    #exampleModalCenter1{
        opacity: 1 !important;
        padding: 0px 35px;
        margin-bottom: 27px;
        border: 1px solid #FFFFFF;
        border-radius: 4px;
        .modal-header{
            justify-content: end;
            border-bottom: 0px;
        }
        .modal-text{
            color: #0D0D0D;
            font-size: 22px;
            font-weight: bold;
            text-align: center;
        }
        
        .modal-button{
            text-align: center;
           
            button{
                padding: 11px 33px;
                margin-bottom: 8px;
                margin-top: 28px;
                background-color: #000000;
                width: 200px;
            }
        }
        .modal-foottext{
            cursor: pointer;
            color: #000000;
            font-size: 18px;
            text-align: center;
            margin-bottom: 27px;
        }
    }
}
.loader{
    text-align: center;
    padding-top: 45vh;
}