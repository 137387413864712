.update-profile{
    margin: auto;
    width: 100%;
  overflow: hidden !important;
    .header_content{
      display: flex;
      margin: 10px 0px; 
     
      .title{
          font-size: 22px;
          font-weight: bold;
          text-align: center;
          margin-bottom: 10px;
          align-items: center;
          justify-content: center;
          display: flex;
          margin: auto;
        }
    }
    .profilepic{
        align-items: center;
        justify-content: center;
        display: flex;
        .avatar {
            width: 85px;
            height: 85px;
            border-radius: 50%;
            border: 1px solid #E3E3E3;
          }
    }
    .profile-fields{
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
      padding: 18px 15px;
        .profile-value{
          color: #707070;
        }
        .novalue{
            color: #D1C8C8;
        }
}
.profile-fields1{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  button{
    font-size: 18px;
    height: 50px;
    background-color: #000000;
    border: 1px solid #000000;
    border-radius: 4px;
    color: #ffffff;
    width: 100%;
}
button:hover{
  background-color: #717171;
  border: 1px solid #717171;
}
}
}
