.spinner-body {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80%;
}
.loading-container{
    height: 100vh;
    background-color: #000;
}
.welcome-img-section-loading{
    padding-top: 15px;
}
.dot-spinner {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.dot-spinner div {
    position: absolute;
    width: 4px;
    height: 4px;
    background: #fff;
    border-radius: 50%;
    animation: dot-spinner 1.2s linear infinite;
}

.dot-spinner div:nth-child(1) {
    animation-delay: 0s;
    top: 37px;
    left: 66px;
}

.dot-spinner div:nth-child(2) {
    animation-delay: -0.1s;
    top: 22px;
    left: 62px;
}

.dot-spinner div:nth-child(3) {
    animation-delay: -0.2s;
    top: 11px;
    left: 52px;
}

.dot-spinner div:nth-child(4) {
    animation-delay: -0.3s;
    top: 7px;
    left: 37px;
}

.dot-spinner div:nth-child(5) {
    animation-delay: -0.4s;
    top: 11px;
    left: 22px;
}

.dot-spinner div:nth-child(6) {
    animation-delay: -0.5s;
    top: 22px;
    left: 11px;
}

.dot-spinner div:nth-child(7) {
    animation-delay: -0.6s;
    top: 37px;
    left: 7px;
}

.dot-spinner div:nth-child(8) {
    animation-delay: -0.7s;
    top: 52px;
    left: 11px;
}

.dot-spinner div:nth-child(9) {
    animation-delay: -0.8s;
    top: 62px;
    left: 22px;
}

.dot-spinner div:nth-child(10) {
    animation-delay: -0.9s;
    top: 66px;
    left: 37px;
}

.dot-spinner div:nth-child(11) {
    animation-delay: -1s;
    top: 62px;
    left: 52px;
}

.dot-spinner div:nth-child(12) {
    animation-delay: -1.1s;
    top: 52px;
    left: 62px;
}

@keyframes dot-spinner {

    0%,
    20%,
    80%,
    100% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.5);
    }
}