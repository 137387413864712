.your-size {
    .heading {
        padding-bottom: 5px;
    }

    ul {
        list-style-type:none !important;
        padding-left: 0px !important;
    }


    li {
        font-size: 14px;
        color: #707070
    }
    li:hover {
        background-color: white;
    }

    #nav-tab {
        flex-wrap: nowrap;
        overflow-y: hidden;
        width: 100%;
        overflow-x: scroll;


        .nav-link {
            background-color: #fff;
            color: #000;
            font-size: 12px;
            border-radius: 0;
            padding: 0px 20px;
            border-color: black;
            border-bottom: 1px solid #000;
            border-right: none;
            margin: 0;
            img{
                max-width: 20px;
                display: block;
                border: 0px;
                margin: 0px auto;
            }
        }

        .border-left-last {
            border-right: .5px solid #000;
        }

        .active {
            background-color: #000;
            color: #fff;

            img {
                filter: invert(1);
            }
        }

        #nav-shirt-tab.active {
            img {
                filter: invert(0) !important;
            }
        }

        .nav-tabs {
            justify-content: center;


        }
    }

    #nav-tab::-webkit-scrollbar {
        display: none;
    }

    #nav-shirt-tab img {
        filter: invert(1) !important;
    }

    #nav-tabContent {
        padding-top: 30px;
    }

    .tab-content {
        .Chart {
            font-size: 14px;

            img {
                padding: 5px;
            }
        }

        .cat-row {
            padding: 15px 0;
            border-bottom: 1px solid #C1C1C1;

            p {
                font-size: 14px
            }

            input {
                border: 1px solid #707070;
                border-radius: 4px;
                background: #EAEAEA;
                padding: 10px;
                font-size: 12px;
                width: 100%;
            }

            .edit-sec {
                text-align: center;
            }

            .reverse-sec {
                text-align: center;
            }
        }
    }
}
.sizeChanrtCms{
    img{
        width: 100% !important;
    }
    h1{
        font-size: 16px !important;
        font-weight: 800 !important;
    }
}
.yourSizeSticky{
    position: sticky;
    z-index: 10;
    background-color: white;
}
.yourSizeTabContent{
    padding: 10px;
}