.welcomeSection {
    background: black;
    height: 100vh;
    background-image: url(../../assets/images/circle-gradient.svg), url(../../assets/images/circle-gradient.svg);
    background-position: top -100px left -225px,
        bottom -446px left -59px;
    background-size: 500px, 700px;
    background-repeat: no-repeat, no-repeat;
    padding-right: 30px;
    padding-left: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    // padding-top: 100px;

    .welcome-img-section {
        text-align: left;
        padding-bottom: 50px;
        padding-left: 21%;

        img {
            width: 173.16px;
            height: 77px;

        }
    }

    .welcome-text-section {
        color: #fff;
        text-align: center;

        .welcome-text-rd {
            color: #fff !important;
            font-size: 22px;
            font-weight: var(--headingbold);
            padding: 10px 0;
        }

        p {
            font-size: 14px;
            padding-bottom: 20px;
        }

        button {
            font-size: 18px;
            padding: 11px 29px
        }


    }
}