.container1 {
    margin: auto;

    .position {
        position: sticky;
        top: 0;
        z-index: 100;
        overflow: hidden !important;
        background-color: white;
    }

    .responsive-map {
        img {
            width: 100%;
            height: 360px;
        }
    }

    .store-sec {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 33px 15px 0px 15px;
        background-color: white;

        .store-sec1 {
            font-size: 18px;
            font-weight: bold;

        }

        .store-sec1-1 {
            display: flex;
            width:100%;
            justify-content: flex-end;
            // align-items: center;
        }

        .store-sec2 {
            // background-color: #EAEAEA;
            // border: solid #E6E6E6 0.5px;
            border-radius: 4px;
            margin-right: 5px;

            img {
                width: 15.5px;
                height: 18px;
                // margin: 13px 0px 9px 20px;
                background-color: #EAEAEA;
            }

            .store-sec2-text {
                // background-color: #EAEAEA;
                font-size: 10px;
                // margin: 11px 10px 10px 4px;
                color: #151515;
            }
        }

        .store-sec3 {
            background-color: #EAEAEA;
            border: solid #E6E6E6 0.5px;
            border-radius: 4px; 
            max-width: 140px;
            width: 100%;
            height: min-content;
            img {
                width: 4.77px;
                height: 8.68px;
                margin: 18px 12px 13.23px 0px;
                background-color: #EAEAEA;
            }

            .store-sec3-text {
                background-color: #EAEAEA;
                color: #717171;
                font-size: 10px;
                margin: 11px 25px 10px 11px;
            }

        }
    }

    .belowcontent {
        // margin-right: 35%;
        // text-align: center;
        background-color: white;

        .belowtext {
            font-size: 9px;
            color: #717171;
            // margin-left: 3px;
        }

        img {
            margin-top: 2px;
        }
    }

    .scrollable {
        overflow-y: auto;
        max-height: 380px;

        hr {
            border: .5px solid #000;
        }

        .GQSIZE-Store {
            padding: 15px;
        }

        .flagship {
            display: flex;
            align-items: center;
            justify-content: space-between;
            // margin: 33px 15px 0px 0px;

            .flagship-left {
                font-size: 16px;
                font-weight: bold;
                max-width: 225px;
            }

            .right-content {
                display: flex;
                align-items: center;

                img {
                    width: 4.23px;
                    height: 7.69px;
                    margin: 3px 0px 3.5px 8.77px;
                }

                .right-text {
                    color: #3478F6;
                    font-size: 14px;
                    margin: 0px 0px 3.5px 0px;
                }

            }
        }

        .address {
            font-size: 16px;
            color: #707070;
        }

        .shop-but {
            width: 69px;
            height: 17px;
            margin: 14.5px 0px 0px 0px;
            font-size: 10px;
            border: 0.5px solid #C1C1C1;
            border-radius: 9px;
            text-align: center;
        }

        .shop-button {
            display: flex;

            .shop-but1,
            .shop-but2,
            .shop-but3 {
                width: 69px;
                height: 17px;
                margin: 14.5px 0px 0px 15px;
                font-size: 10px;
                border: 0.5px solid #C1C1C1;
                border-radius: 9px;
                text-align: center;
            }

            .shop-but1 {
                background-color: #000000;
                color: #fff;
            }
        }

        .image-container {
            position: relative;
            width: 100%;
            max-width: 600px; // Adjust the max-width as needed
        }

        img {
            width: 100%;
            height: auto; // Maintain aspect ratio
            display: block;
        }

        .text-overlay {
            position: absolute;
            top: 50%;
            left: 38%;
            transform: translate(-50%, -50%);
            color: #000000;
            background-color: #f4f4f4;
            text-align: left;
            width: 60%;
            box-sizing: border-box;

            .text1 {
                font-size: 18px;
                background-color: #f4f4f4;
                font-weight: bold;
            }

            .text2 {
                font-size: 14px;
                background-color: #f4f4f4;
            }
        }

        .GQSIZE-Store {
            @extend .image-container;
        }

        .GQSIZE-Store img {
            @extend img;
        }

        .GQSIZE-Store .text-overlay {
            @extend .text-overlay;
        }

    }

    .eachStore {
        border-bottom: 1px solid #ccc;
        padding: 33px 15px 20px 15px;
        &:first-child {
            border-top: 1px solid #ccc;
        }
    }
    .activeStore{
        background-color: #F0F0F0;
    }

    .gm-style-iw-c {
        padding: 10px 20px;

        .gm-style-iw-d {
            .store-name {
                font-size: 18px;
                font-weight: 500;
            }
        }

        button {
            span {
                &:focus-visible {
                    outline: -webkit-focus-ring-color auto 1px;
                }
            }
        }
    }
}

.store-sec3 select {
    width: 95%;
    height: 40px;
    padding: 9px 15px;
    font-size: 10px;
    color: #717171;
    background-color: #EAEAEA;
    border: solid #E6E6E6 0.5px;
    border-radius: 4px;

    &:focus-visible {
        outline: none;
    }
}
.searchlocatorWrap{
    max-width: 250px;
    display: flex;
    justify-content: space-around;
    width: 100%;
    background: #fff;
    border: 0px solid #C1C1C1;
}
.storesNearmeWrap{
    max-width: 180px;
    display: flex;
    width: 100%;
    // justify-content: space-around;
    align-items: center;
    background: #fff;
    border: 1px solid #C1C1C1;
    height: 52px;
    margin: 0.5rem 0.5rem 0.5rem 0px !important;
    border-radius: 4px;
}
.storesNearme{
    padding-left: 7px !important;
    background: none;
    border: none;
    color: #666;
}
.pinlocWrap{
    padding: 0px 0px 3px 10px;
    // @media (max-width:325px) {
    //     padding: 0px 0px 3px 0px;
    // }
}
.serachIconWrap{
    display: flex;
    background-color: white;
    border: 1px solid #C1C1C1;
    border-radius: 4px;
    padding: 0px 0px 0px 10px;
}
.pinLocator{

    max-width: 15px;
    
}
.searchIcon{
    min-width: 15px;
    max-width: 25px;
}
#searchStore{
    border: 0px solid #C1C1C1;
    border-radius: 4px;
}
.dropdown{

    background-color: white;
    max-height: 200px;
    overflow-y: scroll;
    overflow-x: hidden;
    
}
.liHover:hover {
    background-color: #C1C1C1 !important;
    color: white !important;
    /* Add other styles for hover effect */
  }

  .loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .blurred-background {
    background-color: transparent;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(5px); /* Apply blur effect */
  }
  
  /* Additional styling for the loader component */
  
