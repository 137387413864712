.profile_login-container {
  margin: auto;
  width: 100%;
  align-items: center;
  justify-content: center;

  .profile_top-background {
    background: linear-gradient(#000000, #410000);
  }

  .sign-in_btn {
    background-color: #DA2128;
    border: 1px solid #DA2128;
    border-radius: 4px;
    padding: 5px 20px;
    font-size: 12px;
    margin-right: 15px;
    height: fit-content;
  }

  .profile_top {
    // background-color: #D5D5D5 ;
    // background: #D5D5D5 !important;
    background: linear-gradient(#000000, #410000);
    height: 129px;
    display: flex;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    position: sticky;
    top: 0;
    z-index: 100;
    overflow: hidden !important;
  }

  .profile_avatar {
    background: linear-gradient(#000000, #410000);
    margin: 35px 0px 0px 14px;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: 1px solid #9A9A9A;
  }

  .profile_profile_text {
    background: linear-gradient(#000000, #410000);
    margin: 48px 0px 0px 15px;
    font-size: 18px;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 25px
  }

  .profile_profile_header {
    margin: 40px 0px 8px 14px;
    font-size: 22px;
    font-weight: bold;
  }

  a {
    color: #0D0D0D;
    text-decoration: none;
  }

  .profile_header_content {
    margin: 0px 15px 0px 15px;
    padding: 8px 15px 8px 21px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    .profile_subheader {
      font-size: 14px;
    }

  }

  .profile_arrow_image {
    margin-left: auto;
    width: 6px;
    transform: rotate(180deg);
  }


  .profile_header_content:hover {
    background: #C1C1C1;
    border-radius: 2px;
  }

  .profile_header_content1 {
    margin: 0px 15px 0px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 15px 8px 21px;

    .profile_lang_arrow {
      display: flex;
    }

    .profile_material-icons {
      margin-top: 4px;
      font-size: 14px;
    }

    .profile_subheader {
      font-size: 14px;
    }

    .profile_lang {
      font-size: 14px;
      color: #707070;
      margin-right: 10px;
    }
  }

  .profile_header_content1:hover {
    background: #C1C1C1;
    border-radius: 2px;
  }

  .profile_logout {
    margin: 0px 30px 10px 7px;
    display: flex;
    padding: 8px 0px;

    .Logout_sec {
      cursor: pointer;
    }


    .profile_material-icons {
      font-size: 14px;
    }

    .profile_title {
      font-size: 14px;
      margin-top: -2px;
      font-weight: bold;
      padding-left: 8px;
      cursor: pointer;
    }
  }

  .logout_popupModal {
    .modal-header {
      justify-content: end;
      border: none;
    }

    .modal-text {
      color: #000000;
      font-size: 22px;
      text-align: center;
      margin-top: 15px;
    }

    .popup-button {
      display: flex;
      justify-content: center;
      width: 100%;

      .modal-button1 {
        text-align: center;
        margin-right: 10px;

        button {
          padding: 11px 26px;
          margin-bottom: 26px;
          margin-top: 30px;
          background-color: #E4E4E4;
          border: 1px solid #E4E4E4;
          color: #000000;
          width: 100%;
          border-radius: 4px;
        }

        button:hover {
          background-color: #D1D1D1;
          border: 1px solid #D1D1D1;
        }
      }

      .modal-button2 {
        text-align: center;
        margin-right: 10px;

        button {
          padding: 11px 21px;
          margin-bottom: 26px;
          margin-top: 30px;
          background-color: #000000;
          border: 1px solid #000000;
          color: #FFFFFF;
          border-radius: 4px;
          width: 100%;
        }

        button:hover {
          background-color: #707070;
          border: 1px solid #707070;
        }

      }
    }
  }
}