.edit-profile {
    margin: auto;
    width: 100%;
    overflow: hidden !important;

    .ReactFlagsSelect-module_selectBtn__19wW7 {
        height: 50px;
    }

    .ReactFlagsSelect-module_filterBox__3m8EU input {
        /* Your custom styles here */
        margin: 0px 0px;
        /* Add any other styles as needed */
    }

    .ReactFlagsSelect-module_selectOptionsWithSearch__1W03w {
        width: 92vw;
        max-height: 200px;
    }

    .ReactFlagsSelect-module_selectBtn__19wW7 {
        height: 50px;
        background-color: #F2F2F2;
    }

    .ReactFlagsSelect-module_filterBox__3m8EU {
        padding-top: 0px;
    }

    .ReactFlagsSelect-module_selectOptions__3LNBJ {
        margin-top: 0px;
    }

    .ReactFlagsSelect-module_filterBox__3m8EU input {
        background-color: #fff !important;
    }

    .ReactFlagsSelect-module_flagsSelectInline__cUnnz {
        pointer-events: none;
    }

    .ReactFlagsSelect-module_selectBtn__19wW7:after {
        display: none;
    }

    .avatar-upload {
        position: relative;
        max-width: 70px;
        margin: 23px auto;
        
        // opacity: 55%;
        .avatar-edit {
            position: absolute;
            right: 12px;
            z-index: 1;
            top: 25px;

            input {
                display: none;
            }

            .fileimage {
                display: inline-block;
                width: 80px;
                height: 80px;
                position: absolute;
                top: -25px;
                left: -56px;
                cursor: pointer;
                font-weight: normal;

                &:after {
                    content: "\f040";
                    font-family: 'FontAwesome';
                    color: #757575;
                    position: absolute;
                    top: 10px;
                    left: 0;
                    right: 0;
                    text-align: center;
                    margin: auto;
                }
            }
        }

        .avatar-preview {
            
            width: 85px;
            height: 85px;
            position: relative;
            border-radius: 100%;
            background-color: #1A1A1A;
            opacity: 55%;
            >div {
                width: 85px;
                height: 85px;
                border-radius: 100%;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
            }
        }
    }

    .inputfield {
        padding: 15px 15px 0px;

        .disabled {
            // background-color: #F2F2F2;
        }
    }

    label {

        padding-bottom: 4px;
        font-size: 14px;
        color: #0D0D0D;
    }

    .inputField {
        width: 100% !important;
        // min-width: 100% !important;
        height: 50px;
        padding: 15px 10px;
        font-size: 14px;
        color: #000000;
        border: solid #C1C1C1 1px;
        border-radius: 4px;
    }::placeholder {
        color: black; /* Change placeholder color to gray */
      }
      .inputFieldDatePicker {
        -webkit-text-fill-color:black !important;
        width: 100%;
        font-size: 14px;
        color: #000000;
    }::placeholder {
        color: black; /* Change placeholder color to gray */
      }

    select {
        width: 100%;
        height: 50px;
        padding: 15px 10px;
        font-size: 14px;
        color: #000000;
        border: solid #C1C1C1 1px;
        border-radius: 4px;
    }

    .mobile {
        display: flex;

        select {
            width: 30%;
            border-radius: 4px 0px 0px 4px;
            background-color: #F2F2F2;
        }

        input {
            width: 100%;
            border-radius: 0px 4px 4px 0px;
            border-left: 0px;
            background-color: #F2F2F2;
        }
        .customFlagSelect{
            .ReactFlagsSelect-module_label__27pw9,.ReactFlagsSelect-module_secondaryLabel__37t1D {
               color:rgba(0, 0, 0, 0.38);
            }
        }
    }

    .moblabel {
        display: flex;
        justify-content: space-between;

        label {
            text-align: left;
        }

        .labelsub {
            padding-bottom: 4px;
            font-size: 12px;
            text-align: right;
            color: #707070;
        }
    }

    .radiobutton {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        input {
            height: 15px;
            margin: 0;
            max-width: 35px;
        }

        .radiolabel {
            font-size: 14px;
            color: #000000;
            margin-right: 5px;
            white-space: nowrap;
        }
    }

    .doblabel {
        display: flex;
        justify-content: space-between;

        label {
            text-align: left;
        }

        .labelsub {
            padding-bottom: 4px;
            font-size: 12px;
            text-align: right;
            color: #707070;
        }
    }

    .profile-fields1 {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 10px;
        background-color: white;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;

        button {
            font-size: 18px;
            height: 50px;
            background-color: #000000;
            border: 1px solid #000000;
            border-radius: 4px;
            color: #ffffff;
            width: 100%;
        }

        button:hover {
            background-color: #717171;
            border: 1px solid #717171;
        }
    }

}

  
  .dropdown-editprofile{

    background-color: white;
    max-height: 200px;
    overflow-y: scroll;
    overflow-x: hidden;
    width:92%;
    @media (min-width: 450px) {
        width:94%;
      }
      @media (min-width: 600px) {
        width:95%;
      }
      @media (min-width: 700px) {
        width:96%;
      }
      @media (min-width: 850px) {
        width:97%;
      }
}
css-15flkq4-MuiFormControlLabel-root .MuiFormControlLabel-label{
    font-size: 14px !important;
}

// .inputFieldDatePicker.disabled {
//     color: black; /* Set the desired font color for the disabled state */
//     -webkit-text-fill-color:black !important;
//   }
  .error-message{
    font-size: 12px;
    color: #CC0000;
  }
  .react-datepicker-wrapper{
    width:100% !important;
    input {
 width:100%;
 padding: 15px 10px;
 font-size: 14px;
    }
  }
  .dob{
    -webkit-appearance: none;
    background-color: white;
    text-align: left;
  }
  .MuiInputBase-input {
text-align: left;
  }
  .MuiOutlinedInput-input {
    text-align: left;
  }
  .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input{
    text-align: left !important;
  }
  input::-webkit-date-and-time-value{
    display: block;
    text-align: left !important;
  }
  .loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .blurred-background {
    background-color: transparent;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(5px); /* Apply blur effect */
    filter: blur(5px);
  }