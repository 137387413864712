.notification-list{
   
    .deleteicon{
        text-align: end;
        cursor: pointer;
    }
    .rotateimg{
        transform: rotate(180deg);
        width: 5px;
        padding-top: 2px;
    }
 .rounded-checkbox {
    width:14px;
    height: 14px;
    border-radius: 50%;
    vertical-align: middle;
    border: 1px solid black;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
   
  }
  .checkround{
    margin-left: 12px;
    margin-top: 16px;
  }
  .rounded-checkbox:checked {
    appearance: auto;
    clip-path: circle(50% at 50% 50%);
    background-color: blue;
  }
.checkboxlabel{
    padding-left: 5px;
    font-size: 12px;
    color: #8B8B8B;
}
.notification_challengeHistory_row {
    border-bottom: 1px solid #C1C1C1;
    padding-bottom: 20px;
    padding-top: 20px;
    font-size: 12px;
    .rounded-checkbox {
        width:14px;
        height: 14px;
        border-radius: 50%;
        vertical-align: middle;
        border: 1px solid black;
        appearance: none;
        -webkit-appearance: none;
        outline: none;
        cursor: pointer;
        
      }
      .checkround1{
        margin-left: 0px;
        margin-bottom: 6px;
      }
      .rounded-checkbox:checked {
        appearance: auto;
        clip-path: circle(50% at 50% 50%);
        background-color: blue;
      }
    .checkboxlabel{
        padding-left: 5px;
        font-size: 12px;
        color: #8B8B8B;
    }
    .noti-head{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    p {
        font-weight: 400
    }
    li{
        padding-left: 0px;
        color: #00A35E;
        font-size: 18px;
    }
    ul{
        padding-left: 0px;
        margin-bottom: 0px;
    }
    .noti-text{
        color: #000;
        font-weight: bold;
        font-size: 14px;
    }
    .pointGreen {
        padding: 5px 5px;
        color: #0D0D0D;
        font-size: 14px;
    }

    .pointRed {
        color: #DA2128
    }

    .expDate {
        color: #000000;
    }
}
#exampleModal{
    opacity: 1 !important;
    margin-bottom: 27px;
    border-radius: 4px 4px 0px 0px;
    
    .modal-header{
        justify-content: end;
        border-bottom: 0px;
        padding: 15px 15px 27px;
    }
    .modal-dialog{
        transform: none;
    }
    .modal-text{
        color: #000000;
        font-size: 22px;
        text-align: center;
    }
   
    .popup-button{
        display: flex;
        justify-content: center;
        width: 100%;
        padding-top: 26px;
        padding-bottom: 45px;
    .modal-button1{
        text-align: center;
       margin-right: 10px;
        button{
            padding: 11px 25px;
            background-color:#E4E4E4;
            border: 1px solid #E4E4E4;
            color: #000000;
            width: 100%;
            border-radius: 4px;
        }
        button:hover{
            background-color:#D1D1D1;
            border: 1px solid #D1D1D1;
        }
    }
    .modal-button2{
        text-align: center;
       margin-right: 10px;
        button{
            padding: 11px 25px ;
            background-color: #000000;
            border: 1px solid #000000;
            color: #FFFFFF;
            border-radius: 4px;
            width: 100%;
        }
        button:hover{
            background-color:#707070;
            border: 1px solid #707070;
        }
    }
}
    
}
}