.track-order-section {
    color: #000000;

    .track-order-box {
        text-align: center;
        padding-top: 50px;
        padding-bottom: 50px;

        p {
            padding: 10px 50px;
        }
    }

    h5 {
        font-size: 18px;
        font-weight: bold;
        padding-top: 20px;
        padding-bottom: 10px;
    }

    p {
        font-size: 14px;
    }


    button {
        background: #000;
        border: 1px solid #000;
        padding: 10px 20px;
        font-size: 18px;

        &:hover {
            background-color: #717171;
            border-color: #717171
        }
    }

    .shipping-box {
        padding: 10px;
        background-color: #F7F7F7;
        border-radius: 4px;
    }
}
.comman-button{
    a{
        color: inherit;
        text-decoration: none;
    }
}