
.verify-container1{
  .col-2{
    margin: 0px 15px !important;
  }
}
.verify-container{
    margin: auto;
    width: 100%;
    padding: 0px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #0D0D0D;
   
    .header_content{
      display: flex;
      margin-bottom: 10px; 
    }
    .material-icons{
      margin-bottom: -5px;
      font-size: 20px;
    }
      .title{
      font-size: 22px;
      font-weight: bold;
      text-align: center;
      margin-bottom: 10px;align-items: center;
      justify-content: center;
      display: flex;
      margin: auto;
    }
    .login-content{
        width: 95%;
        display: flex;
        flex-direction: column;
      }
      .otplabel{
        font-size: 14px;
        text-align: center;
        color: #000;
        margin-top: 34px;
      }
      .otpbox{
        text-align: center;
        margin: 14px 0px 8px 0px;
      }
      .resend{
        font-size: 14px;
        text-align: center;
        color: gray;
        margin-bottom: 35px;
      }
      .resendbold{
        color: #000;
        text-decoration: underline;
        font-weight: bold;
      }
      .alternative{
        font-size: 14px;
        text-align: center;
        margin-top: 16px;
      }
      .resenddiv{
        padding-bottom: 20px;
        padding-top: 10px;
      }
      button{
        
        font-size: 18px;
        height: 50px;
        background-color: #000000;
        border: 1px solid #000000;
        border-radius: 4px;
        color: #ffffff;
        width: 100%;
    }
    button:hover{
      background-color: #717171;
      border: 1px solid #717171;
    }
    .inputfield {
      width: 100%;
      padding: 14px 50px 8px 50px;
      display: flex;
      justify-content: space-around;
      .input {
        margin-left: 5px;
        height: 51px;
        width: 45px;
        border: 1px solid #C1C1C1;
        outline: none;
        text-align: center;
        font-size: 14px;
        border-radius: 4px;
        background-color: #ffffff;
        outline: none;
        -moz-appearance: textfield;
      }
    }
    
  }
