.earnprogress {
    .img-section {
        width: 100%;

        img {
            border: 1px solid #ccc;
        }
        .special{
            width: 90px;
            padding: 3px 20px;
            text-align: center;
            font-size: 14px;
            color: #FFFFFF;
            background-color: #4B4B4B;
            border: 1px solid #4B4B4B;
            border-radius: 14px;
            position: absolute;
            top: 70px;
            left: 15px;
        }
    }

    .challenger-box {
        .heading{
            margin-top: 25px;
            display: flex;
            justify-content: space-between; // Aligns items to each end of the container
  align-items: center;
            h5 {
                font-size: 16px;
                color: #0D0D0D;
              
                margin: 0;
            }
            .left-item{
                font-size: 14px;
                color: #717171;
               
                padding-top: 8px;
                margin: 0;
            }
            
        }
        .GQ-Coin{
            width: 15px;
            height: 15px;
            margin-bottom: 2px;
        }
        .store-button{
            padding-top: 3px;
            display: flex;
            .store-but1, .store-but2, .store-but3{
                width: 69px;
                height: 17px;
                margin-left: 4px;
                font-size: 10px;
                border: 0.5px solid #C1C1C1;
                border-radius: 9px;
                text-align: center;
            }
            .store-text{
                font-size: 14px;
                color: #0D0D0D;
                margin-right: 6px;
            }
          }
        .progress {
            border: 1px solid #707070;
            margin-bottom: 5px;
            height: 5.16px;
            background-color: #fff;
            margin-top: 5px;

            .progress-bar {
                background: var(--darkgray);
            }
        }

        .Ponit_status {
            p {
                font-size: 10px;
            }
        }

        .challenger-details {
            .pointBold {
                font-weight: var(--semibold);
                padding-left: 4px;
            }
        }

        .howWork {
            padding: 20px 0;
            .title {
                font-weight: bold;
                font-size: 14px;
            }

            .description {
                font-size: 14px;
                color: #707070;
            }
        }
    }
    .savebutton{
        background-color: white;
        width: 100%;
        position: fixed;
        bottom: 0;
        left: 0;
        padding: 10px 15px 20px;
        border-top: 0.25px solid #C1C1C1;
        .above-button{
            font-size: 14px;
            color: #717171;
        }
        button{
            font-size: 18px;
            height: 50px;
            background-color: #959595;
            border: 1px solid #959595;
            border-radius: 4px;
            color: #ffffff;
            width: 100%;
        }
        .redeem-img{
            margin-bottom: 4px;
        }
        button:hover{
            // background-color: #6B1111;
            // border: 1px solid #6B1111;
        }
    }
    .footerEndSp {
        margin-bottom: 105px !important;
    }
    .Description{
        font-size: 14px;
    }
}