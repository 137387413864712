.your-size {
    .heading {
        padding-bottom: 5px;
    }

    ul {
        list-style: num;
        padding-left: 20px;
    }


    li {
        font-size: 14px;
        color: #707070
    }

    #nav-tab {
        flex-wrap: nowrap;
        overflow-y: hidden;
        width: 100%;
        overflow-x: scroll;


        .nav-link {
            background-color: #fff;
            color: #000;
            font-size: 12px;
            border-radius: 0;
            padding: 5px 10px;
            border-color: black;
            border-bottom: 1px solid #000;
            border-right: none;
            margin: 0;
            flex-grow: 1;
            flex-basis: 0;
        }

        .border-left-last {
            border-right: .5px solid #000;
        }

        .active {
            background-color: #000;
            color: #fff;

            img {
                filter: invert(1);
            }
        }

        #nav-shirt-tab.active {
            img {
                filter: invert(0) !important;
            }
        }

        .nav-tabs {
            justify-content: center;


        }
    }

    #nav-tab::-webkit-scrollbar {
        display: none;
    }

    #nav-shirt-tab img {
        filter: invert(1) !important;
    }

    #nav-tabContent {
        padding-top: 30px;
    }

    .tab-content {
        .Chart {
            font-size: 14px;

            img {
                padding: 5px;
            }
        }

        .cat-row {
            padding: 15px 0;
            border-bottom: 1px solid #C1C1C1;

            p {
                font-size: 14px
            }

            select {
                border: 0px solid #707070;
                border-radius: 4px;
                background: #EAEAEA;
                outline: none;
                font-size: 12px;
                width: 100%;
            }

            .select_div {
                // border: 1px solid #707070;
                border-radius: 4px;
                // background: #EAEAEA;
                padding: 10px;
                font-size: 12px;
                display: flex;
                align-items: center;
                // width: 120px;
                // margin-right: 10px;
                position: relative;
                height: 40px;
                label{
                    position: relative;
                    z-index: 1;
                    pointer-events: none;
                }
                select{
                    text-align: left;
                    position: absolute;
                    width: auto;
                    min-width: 110px;
                    max-width: 150px;
                    top: -1px;
                    padding: 9px !important;
                    height: auto;
                    border: 1px solid #000;
                    left: -1px;
                }
                select {

                    // -webkit-appearance: none; 
                    // -moz-appearance: none;
                    // appearance: none;
                    
                    -moz-border-radius: 4px;
                    -webkit-border-radius: 4px;
                    border-radius: 4px;
                    // background: url('../../assets/images/Icons/dropdown.svg') no-repeat;
                    background-position: right center;
                    background-color: #eaeaea;
                    color: #000000;
                    border: 1px solid #707070;
                    height: 45px;
                   
                    }
            }
        }
    }
}

.edit-your-size {
    .comman-button {
        margin-top: 50px;
        padding: 8px 50px;
        background-color: #000;
        border: 1px solid #000;
        font-size: 18px;
        border-radius: 5px;
        &:hover {
            background: #959595;
            border: 1px solid #959595
        }
    }

    .modal-footer,
    .modal-header {
        border: none;
    }

    .modal-dialog {
        padding: 36px
    }

    .modal {
        h5 {
            font-size: 22px;
        }
    }

    .text-black {
        color: #000
    }
}

.inactive-button {
    margin-top: 50px;
    padding: 8px 50px;
    background: #959595;
    border: 1px solid #959595;
    font-size: 18px;
    color: white;
    border-radius: 5px;

}