.membership {
    background-color: #2B2B2B;

    .earn-pointRange {
        padding: 25px 0;
        border-radius: 0 0 15px 15px;
        color: #fff;

        .progress {
            height: 5.16px;
            background-color:var(--darkgray);
            margin-top: 5px;
            margin-bottom: 10px;

            .pointProgressBar {
                background: #fff;
            }
            .progress-bar{
                background-color: #707070;
            }
        }

        .points {
            font-size: 12px;
        }

        h5 {
            font-size: 22px;
            font-weight: bold;
        }

        .pointBold {
            font-weight: bold;
            font-size: 18px;
        }

        .nextTierText {
            font-size: 9px;
            color: #fff
        }

        .memberShip_tier_heading {
            text-align: center;
            margin: 0;
            color: #fff;
        }
    }

    .title {
        text-align: left;
        padding-bottom: 0px;
        font-size: 18px
    }

    .tier {
        display: flex;
        justify-content: space-between;

        .nextTier {
            font-weight: var(--regular);
            // color: #717171;
        }
    }


    .friend-box-outer {
        height: 100vh;
        .outer-text {
            padding: 30px 0;
            margin: 0 5px;


            p {
                font-size: 14px;
                font-weight: 500;
                color: #fff;
            }
        }

        p {
            font-weight: bold;
            font-size: 16px;
            color: #000;
        }

        .friend-box {
            padding: 40px 20px;
            background-color: #fff;
            border-radius: 4px;
            margin: 0 5px;
            font-size: small;
            // height: 515px;


            .friend-text-first {
                h6{
                    padding-bottom: 15px;
                }
                ul{
                    padding-left: 0rem !important;
                }
                padding: 20px 0px 10px 0px;
            }

            .unlock_tier {
                font-size: 12px;
                color: #707070;
                font-weight: 100;

                img {
                    // padding: 5px;
                }

                a {
                    color: #707070;

                }
            }

            .memberShip_TierName {
                font-size: 22px;
                font-weight: var(--headingbold);
                padding: 10px 0;
                color: #000;
            }
        }

        .list-ul li {
            padding-bottom: 15px;
            list-style: none;
            font-size: 14px;
            color: #000;
        }

        li {
            list-style: none;
          background: url('../../assets/images/gray-check.png') no-repeat;
          background-size: 15px 15px; /* adjust the size of the image */
          padding: 0px 5px 15px 25px;
          align-items: center;
            cursor: pointer;
            color: black;
        }
        
        li:hover {
            background-color: white;
        }

        .seeAll {
            color: #4B4B4B;
            font-weight: 600;
            font-size: 14px;
            text-align: center;
        }

        .carousel-indicators button {
            width: 10px;
            height: 10px;
            border-radius: 100%;
            border: 1px solid #fff;
        }

        .carousel-indicators [data-bs-target] {
            width: 10px;
            height: 10px;
            border-radius: 100%;
            border: 1px solid #707070;

        }

        .carousel-indicators {
            bottom: -20px !important;
        }

        .carousel-indicators button.active {
            background-color: #717171;
            border: none;
            width: 12px;
            height: 12px;
        }

    }
}