.new-password{
    margin: auto;
    padding: 0px 15px;
    width: 100%;
    align-items: center;
    justify-content: center;
    label{
      padding: 10px 0px 4px 0px;
      font-size: 14px;
      color: #0D0D0D;
  }
  input{
      width: 100%;
      height: 50px;
      padding: 15px 10px;
      font-size: 14px;
      color: #000000;
      border:solid #C1C1C1 1px;
      border-radius: 4px;
  }
  .error-message{
    font-size: 12px;
    color: #CC0000;
  }
  select{
      width: 100%;
      height: 50px;
      padding: 15px 10px;
      font-size: 14px;
      color: #000000;
      border:solid #C1C1C1 1px;
      border-radius: 4px;
  }
  .mobile{
      display: flex;
      select{
          width:30% ;
          border-radius: 4px 0px 0px 4px;
      }
      input{
          width: 70%;
          border-radius: 0px 4px 4px 0px;
          border-left: 0px;
      }
  }
  .moblabel{
      display: flex;
      justify-content: space-between;
      label{
          text-align: left;
      }
      .labelsub{
          padding: 10px 0px 4px 0px;
      font-size: 12px;
      text-align: right;
      color: #707070;
      }
  }
  #password{
    font-size: 50px;
  }
  .eyeicon1{
    position: absolute;
    right: 35px;
    top: 197px;
    cursor: pointer;
  }
  .eyeicon2{
    position: absolute;
    right: 35px;
    top: 113px;
    cursor: pointer;
  }
  .savebutton{
          
    width: 100%;
    margin-top: 23px;
    button{
        font-size: 18px;
        height: 50px;
        background-color: #000000;
        border-radius: 4px;
        color: #ffffff;
        width: 100%;
    }
    button:hover{
      background-color: #717171;
      border: 1px solid #717171;
    }
  }
  .forgot-label{
    padding-top: 5px;
    font-size: 14px;
    color: #2172DA;
    text-decoration: underline;
  }
  .alternative{
    text-align: center;
    margin-top: 10px;
    font-size: 14px;
  }
  }