.tellsfriend {
    .tellsfriend-text {
        p {
            font-size: 16px;
            color: #555555;
        }

        .box {
            padding-top: 40px;
            padding-bottom: 40px;

            h5 {
                font-size: 18px;
                font-weight: bold;
                color: #000000;
                padding: 10px 0;
            }

            input {
                border-radius: 4px 0 0px 4px;
                border: 1px solid #707070;
                border-right: 0px;
                padding: 0px 10px 0px 6px;
                height: 30px;
                // padding: 6px 10px;
                // font-size: 12px;
            }

            button {
                border-radius: 0px 4px 4px 0px;
                // font-size: 12px;
                color: #2172DA;
                padding: 0px 10px 0px 6px;
                border: 1px solid #707070;
                height: 30px;
                // margin-top: 1px;
                border-left: 0px;
                // padding: 6.5px 10px 6.5px 10px;
            }

            .social-box {
                p {
                    font-size: 12px;
                    color: #707070;
                    font-weight: 400;
                    padding: 10px 0;
                }

                .social-box-row {
                    justify-content: center;

                    .social-box-item {
                        border: .5px solid #C1C1C1;
                        margin: 0 5px;
                        border-radius: 4px;
                        font-size: 9px;
                        padding: 5px;
                        cursor: pointer;
                        img {
                            padding-right: 5px;
                            padding-bottom: 2px;
                        }
                    }
                }
            }
        }
    }
}
.error-message{
    font-size: 12px;
    color: #CC0000;
  }
  .copyButton{
    background-color: #C9E1FF;
    color: #2172DA;
  }