.year-dropdown {
    padding-top: 15px;
    font-size: 14px;
display: flex;
    select {
        padding: 2px 15px;
        border: 1px solid #C1C1C1;
        border-radius: 4px;
        outline: none;

    }

}

.challengeHistory_row {
    border-bottom: 1px solid #C1C1C1;
    padding-bottom: 20px;
    padding-top: 20px;
    font-size: 12px;

    p {
        font-weight: 400
    }

    .grayText {
        color: #C1C1C1;
    }

    .pointRed {
        color: #DA2128
    }
    .pointGreen {
        padding: 5px 0px;
        color: #00AD2F;
    }
    .rewardHis_expDate {
        color: #717171
    }
}
.no_data{
    text-align: center;
    padding-top: 50px;
}
.custom-select{
    padding-right: 10px;
    select{
        color: black;
    }
}