.toggler-section {
    padding-top: 25px;


    .form-switch {
        padding-bottom: 20px;

        input {
            box-shadow: none;
            width: 3em;
            height: 1.5em;
            cursor: pointer;
        }

        label {
            font-size: 14px
        }

        .form-check-input:checked {
            background-color: #91EA85;
            border: none;
            --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -3 7 6' %3e%3ccircle r='3' fill='%23fff' /%3e%3c/svg%3e") !important
        }

        .form-check-input {
            background-color: #EBEBEB;
            border: none;
            --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-3 -3 7 6' %3e%3ccircle r='3' fill='%23c1c1c1' /%3e%3c/svg%3e") !important
        }

        .form-check-input {
            border-color: #EBEBEB;
        }
    }
}
#exampleModalCenter{
    opacity: 1 !important;
    margin-bottom: 27px;
    border: 1px solid #FFFFFF;
    border-radius: 4px 4px 0px 0px;
    .modal-content{
        // position:fixed;
        top:auto;
        right:0;
        left:0;
        bottom:0;
    }
    .modal-header{
        justify-content: end;
        border-bottom: 0px;
    }
    .modal-dialog{
        transform: none;
    }
    .modal-text{
        color: #0D0D0D;
        font-size: 18px;
        font-weight: bold;
        text-align: center;
        margin-top: 43px;
    }
    .modal-subtext{
        color: #0D0D0D;
        font-size: 18px;
        text-align: center;
        padding:10px 5px 0px 5px
    }
    .popup-button{
        display: flex;
        justify-content: center;
        width: 100%;
    .modal-button1{
        text-align: center;
       margin-right: 10px;
        button{
            padding: 15px 40px;
            margin-bottom: 26px;
            margin-top: 30px;
            background-color:#E4E4E4;
            border: 1px solid #E4E4E4;
            color: #000000;
            width: 100%;
            border-radius: 4px;
        }
        button:hover{
            background-color:#D1D1D1;
            border: 1px solid #D1D1D1;
        }
    }
    .modal-button2{
        text-align: center;
       margin-right: 10px;
        button{
            padding: 15px 40px ;
            margin-bottom: 26px;
            margin-top: 30px;
            background-color: #000000;
            border: 1px solid #000000;
            color: #FFFFFF;
            border-radius: 4px;
            width: 100%;
        }
        button:hover{
            background-color:#707070;
            border: 1px solid #707070;
        }
    }
}
    
}