.welcomeRewardSection {
    background-color: #000;
    padding-top: 15px;
    text-align: center;
    min-height: 100vh;
height: 100%;
    .welcome-img-section {
        text-align: left;
    }

    .welcome-text-section {
        padding-top: 40px;
        color: #fff;
        text-align: center;

        .fixedheigth {
            height: 120px;
            padding-top: 40px;
        }

        .welcome-text_welcomeRewards {
            color: #fff !important;
            font-size: 22px;
            font-weight: var(--headingbold);
            padding: 10px 0;
        }

        h6 {
            font-size: 22px;
        }

        p {
            font-size: 14px;
            padding-bottom: 20px;
        }

        .skip-text {
            padding-top: 20px;
            font-size: 18px
        }

        button {
            font-size: 18px
        }


    }

    .carousel-indicators button {
        width: 10px;
        height: 10px;
        border-radius: 100%;
        border: 1px solid #fff;
    }

    .carousel-indicators [data-bs-target] {
        background-color: #000;

    }

    .carousel-indicators {
        bottom: 80px !important;
    }

    .carousel-indicators button.active {
        background-color: #DA2128;
        border: none;
        width: 12px;
        height: 12px;
    }
}
.reward {
    .title {
        font-size: 18px !important;
    }

    .points {
        color: #717171;
    }

    .pointBold {
        font-weight: bold;
        color: #000;
        font-size: 18px;
    }

    .reward-sec-col {
        cursor: pointer;
        width: 50%;
        padding: 5px;
        .img-box-text {
            min-height: 170px;
        }
    }

    .enoughSec {
        .img-tag {
            background-color: #000;

            img {
                opacity: 0.7;
            }
        }
    }

    .boldhHeadingH5 {
        font-size: 14px;
        margin: 0;
        padding-bottom: 0px;
    }

    .reward_button_store button {
        border-radius: 12px;
        border: 0.5px solid #c1c1c1;
        padding: 0px 10px;
        font-size: 13px;
        margin-right: 5px;
        margin-left: 2px;
        margin-top: 5px;
    }

    .tier {
        padding: 10px 0px;
    }

    .store_channel button {
        font-size: 10px;
    }

    .highest {
        .tag{
            color: #2172da;
        }
        button {
            background-color: #2172da47;
            border: 0.5px solid #2172da47;
        }
    }

    .RewardImgwrap{
        position: relative;
        .thumbnailImgwrap{
            position: relative;
            width: 100%;
            &:after{
                content: "";
                display: block;
                padding-bottom: 100%; 
            }
            img{
                position: absolute; 
                top: 0;
                bottom: 0;
                left: 0;
                right: 0; 
                width: 100%; 
                height: 100%; 
                object-fit: fill;
                border-radius: 4px 4px 0px 0px; 
            }
        }
        button {
            font-size: 10px;
            position: absolute;
            top: 5px;
            left: 15px;
            background-color: #4b4b4b;
            border: 1px solid #4b4b4b;
            color: #fff;
        }
    }
}

.react-silder {

    &.news_silder {
        padding-bottom: 20px;
    }

    .react-multi-carousel-list {
        padding-bottom: 20px;
    }

    // .react-multi-carousel-dot-list {
    //     background-color: #EAEAEA;
    //     width: min-content;
    //     border-radius: 4px;
    //     margin: 0px auto;

    //     li {
    //         padding: 0px;
    //     }
    // }

    // .react-multi-carousel-dot {
    //     display: flex;
    // }

    .react-multi-carousel-dot button {
        background: transparent !important;
        border-color: transparent !important;
        width: 30px;
        height: 6px;
        margin-right: 0px;
        border-radius: 10%;
    }

    .react-multi-carousel-dot--active button {
        border-radius: 5px;
        width: 20px;
        height: 6px;
        border-color: #B5B5B5;
        background-color: #B5B5B5 !important;

    }

    .react-multiple-carousel__arrow {
        z-index: 2;
    }

    .image-box {
        background-color: #fff
    }
}
.dots{
    width:12px;
    height: 12px;
    border-radius: 50%;
    border: 1px solid #707070;
    background-color: black;
    margin: 3px;
}
.welcomerewards-active{
    background-color: #DA2128;
}
.welcomegetstarted{
    background-color: #DA2128;
    border: 1px solid #da2128;
    border-radius: 4px;
    color: #fff;
}