.language {
    padding-top: 30px;

    .form-check {
        padding-bottom: 15px;

        .form-check-input:checked[type=radio] {
            border: 1px solid #2172DA;
            background-color: #fff;
            box-shadow: none;
            --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='1.5' fill='%232172DA'/%3e%3c/svg%3e")
        }

        .form-check-input {
            box-shadow: none;
        }

        label {
            font-size: 14px;
        }
    }

}