.qr-code {
  background-color: #000;

  .invertimage{
    filter: invert(1);
  }
  .cross{
    text-align: end;
    cursor: pointer;
  }
  h5 {
    color: #fff;
  }
  .border-container {
    margin-top: 27px;
    border: 1px solid #707070;
    border-radius: 36px;
    .gqlogoimage {
      padding-top: 18px;
      padding-left: 21px;
      img {
        height: 55px;
      }
    }
    .barcode {
      padding: 29px 21px 0px 21px;
      text-align: center;
      img {
        width: 100%;
      }
    }
    .qrtext {
      font-size: 14px;
      color: #717171;
      text-align: center;
    }
    .copycontent {
      text-align: center;
      padding-top: 16px;
      input {
        width: 146px;
        height: 26px;
        font-size: 12px;
        border: 0.5px solid #707070;
        color: #717171;
        text-align: center;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
      button {
        width: 42px;
        height: 27px;
        font-size: 12px;
        border: 0.5px solid #707070;
        border-left-width: 0px;
        color: #717171;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
    .grcodeimage {
      text-align: center;
      padding-top: 20px;
      padding-bottom: 80px;
      img {
        width: 100px;
        height: 100px;
      }
    }
  }
  .redeem-text {
    cursor: pointer;
    padding-top: 40px;
    text-align: center;
    color: #fff;
    font-size: 18px;

    p {
      display: inline-block;
      border: 1px solid #ffffff;
      border-radius: 4px;
      padding: 11px 20px;
    }
    a {
      display: inline-block;
      border: 1px solid #ffffff;
      border-radius: 4px;
      padding: 11px 20px;
      text-decoration: none;
      color: white;
    }
    p:hover{
      background-color: #707070;
      border: 1px solid #707070;
    }
    a:hover{
      background-color: #707070;
      border: 1px solid #707070;
      color: white;
    }
  }
  .redeem-text-hidden {
    cursor: pointer;
    padding-top: 40px;
    text-align: center;
    color: #fff;
    font-size: 18px;

    p {
      display: inline-block;
      border: 1px solid #ffffff;
      border-radius: 4px;
      padding: 11px 20px;
    }
    
  }
 
}
.barcod-style{
  width:4.2;
  height: 65;
}



.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.blurred-background {
  background-color: transparent;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(5px); /* Apply blur effect */
}
