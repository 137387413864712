.rewardsPage {
    .earn-pointRange {
        padding: 1.5rem 0;
        border-radius: 0 0 15px 15px;

        .points {
            font-size: 12px;
        }

        h5 {
            font-size: 22px;
            font-weight: bold;
            color: #fff
        }

        .boldhHeadingH5 {
            color: #fff;
        }

        .points {
            color: #fff;
        }

        .pointBold {
            color: #fff;
        }

    }

    .boldhHeadingH5 {
        text-align: center;
        padding-bottom: 20px;
        margin: 0;
    }

    .title {
        text-align: left;
        padding-bottom: 0px;
        font-size: 18px;
    }

    .tier {
        display: flex;
        justify-content: space-between;
    }

    .Tier_graySection {
        background-color: var(--darkgray);
        color: #fff;
        border-radius: 0px 0px 4px 4px;

        .tier {
            display: flex;
            justify-content: space-between;
            width: 75%;
            margin: 0 auto;
            padding: 15px 0;

            .nextTier {
                font-weight: var(--regular);
                color: var(--darkgray);
            }

            .points {
                font-size: 12px
            }

            h5 {
                font-size: 19px;
                font-weight: bold;
                margin-bottom: 0;
            }

            .tier-col {
                text-align: center;
                margin: 2px;
            }

            .borderColRight {
                border-right: 1px solid #fff;

            }
        }

        .nextTierText {
            color: #fff;
            padding-bottom: 5px;
        }
    }

    .progress {
        height: 5.16px;
        background-color: #fff;
        margin-top: 5px;
        margin-bottom: 10px;

        .progress-bar {
            background: var(--darkgray);
        }
    }


    .nextTierText {
        color: #000;
        font-size: 9px;
    }
}



.Reward-section-Reward-Page {
    text-align: left;
.GQ-Coin{
    width: 15px;
    height: 15px;
    margin-bottom: 5px;
}
    .image-box {

        .img-box-text {
            padding: 10px;
            padding-bottom: 10px;
            border: 1px solid var(--lightgray);
            border-radius: 0px 0px 4px 4px;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            max-height: 200px;
            overflow-y: scroll;
            min-height: 200px;
            .reward-text {
                // font-size: 12px;
                min-height: 50px;
                padding: 1px 0px 5px;
                font-size: 14px;
            }
            @media (max-width:"410px") {
                padding:10px 10px 10px 2px
            }
        }

        .points {
            font-size: 12px;

            .categoryName {
                border-radius: 12px;
                border: 0.5px solid #c1c1c1;
                padding: 0px 10px;
                font-size: 10px;
                margin-right: 5px;
                // margin-left: 2px;
                margin-top: 10px;
            }
            .pointBold {
                font-weight: bold;
                font-size: 14px;
            }

            .greenfree {
                color: #0CAA37;
                // font-weight: bold;
            }

            .store_channel {
                button {

                    font-size: 10px;
                }
            }

            .freebox {
                border: 0px solid var(--lightgray);
                background-color:#DAF2E1;
                padding: 0px 5px;
                border-radius: 15px;
                font-size: 10px;
            }
        }

        .date {
            // color: $darkgray;
            font-size: 12px;
            padding-top: 10px;
            padding-bottom: 20;
        }
    }
}

.reward-container {
    .title {
        font-size: 18px !important;
    }

    .points {
        color: #717171;
    }

    .pointBold {
        font-weight: bold;
        color: #000;
        font-size: 18px;
    }

    .reward-sec-col {
        width: 50%;
        padding: 5px;
min-width: 150px;
max-width: 400px;
        &:nth-child(odd) {
            padding-left: 0px;
        }

        &:nth-child(even) {
            padding-right: 0px;
        }
        @media (max-width:"320px") {
            width:80%;
        }
    }

    .enoughSec {

        .img-tag {
            background-color: #000;

            img {
                opacity: .7;
            }
        }

    }

    .boldhHeadingH5 {
        font-size: 14px;
        margin: 0;
        padding-bottom: 0px;
    }

    .reward_button_store button {
        border-radius: 12px;
        border: 0.5px solid #C1C1C1;
        padding: 0px 10px;
        font-size: 13px;
        margin-right: 5px;
        margin-left: 2px;
        margin-top: 5px;
    }

    .tier {
        padding: 10px 0px;
    }

    .store_channel button {
        font-size: 10px;
    }

    .highest {
        button {
            color: #2172DA;
            background-color: #2172da47;
            border: 0.5px solid #2172da47;
        }
    }
    .img-tag{
        position: relative;
        border: 0px solid var(--lightgray);
        // border-radius: 0px 4px 0px 0px;
    border-bottom: 0px;
        width: 100%;
        &:after{
            content: "";
            display: block;
            padding-bottom: 100%; 
        }
        img{
            position: absolute; 
            top: 0;
            bottom: 0;
            left: 0;
            right: 0; 
            width: 100%; 
            height: 100%; 
            object-fit: fill; 
            border-radius: 4px 4px 0px 0px;
        }
        button {
            font-size: 8.5px;
            position: absolute;
            line-height: 15px;
            // top: 5px;
            left: 15px;
            background-color: #4B4B4B;
            border: 1px solid #4B4B4B;
            color: #fff;
        }
        .rewards-label-btn{
            font-size: 10px;
            position: absolute;
            top: 5px;
            left: 15px;
            background-color: #4b4b4b;
            border: 1px solid #4b4b4b;
            color: #fff;
        }
        .enough_bal {
            button {
                top: 70%;
                font-size: 8px;
                background-color: #fff;
                color: #707070;
            }
        }
    }

    .couponShow {
        // padding-top: 20px;
        cursor: pointer;

        .coupon {
            padding: 12px;
            border-radius: 4px;
            background-color: #DA2128;
            color: #FFF;
            font-size: 10px;

        }

        .coupon:hover {
            background-color: #6B1111
        }

    }

}
.loader{
    text-align: center;
    padding-top: 45vh;
}
.dark-bg{
    background-color: #000;
    color: white;
}
.white-bg{
    background-color: white;
    color: black;
}
.disbaledRewards{
    margin-left: 0 !important;
    top: 80% !important;
    // background-color: "white";
    color: grey !important;
    left: 5% !important;
    padding: "1px 0px 1px 0px" !important;
    width: 90% !important;
    background-color: white !important;

}
.img-bg-blur{
    background-color: #020000;
    opacity: 65%;
}