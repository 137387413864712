.coupon-card {
    padding-top: 20px;
    .img-tag{
        position: relative;
        width: 100%;
        &:after{
            content: "";
            display: block;
            padding-bottom: 100%; 
          }
      img{
        position: absolute; 
        top: 0;
        bottom: 0;
        left: 0;
        right: 0; 
        width: 100%; 
        height: 100%; 
        object-fit: fill; 
        border-radius: 4px 4px 0px 0px;
      }
    }
    .image-box {
        padding-bottom: 20px;
    }
}

.my-counpn-card{
    min-height: 100px;
}

.mycoupon {
    .humburger-icon {
        filter: invert(1)
    }

}
.no_data{
    text-align: center;
    padding-top: 50px;
}