.track-order-section {
    color: #000000;

    .track-order-box {
        text-align: center;
        padding-top: 70px;

        p {
            padding: 0px 43px 25px;
        }
    }
    h5 {
        font-size: 18px;
        font-weight: bold;
        padding-top: 20px;
        padding-bottom: 10px;
    }
    .join-text{
        padding-top: 25px;
        font-size: 18px;
        font-weight: bold;
    }
    p {
        font-size: 14px;
    }
.mailimg{
    width: 70px;
    height: 50px;
}

    button {
        background: #000;
        border: 1px solid #000;
        padding: 11px 35px;
        font-size: 18px;

        &:hover {
            background-color: #717171;
            border-color: #717171
        }
    }

    .shipping-box {
        padding: 10px;
        background-color: #F7F7F7;
        border-radius: 4px;
    }
}
.joinus-back-arrow{
    text-align: center !important;
}