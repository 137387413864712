@import url('https://fonts.googleapis.com/css2?family=Prompt:wght@400;500;600;700;800&display=swap');

html {
    scroll-behavior: smooth;
}

::-webkit-scrollbar-thumb {
    // background: #020202;
    // border-radius: 0px;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

*,
*::before,
*::after {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}


// comman css
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Prompt', sans-serif;
}

// .section.container-fluid {
//     padding-right: 0 !important;
//     padding-left: 0 !important;
// }

p {
    margin-top: 0;
    margin-bottom: 0 !important;
}

h5,
h6 {
    margin-bottom: 0 !important;
}

// $ligthergray: #f1f1f1;
// $lightgray: #e1dede;
// $darkgray: gray;
// $headingbold: 800;

:root {
    --ligthergray: #f1f1f1;
    --lightgray: #e1dede;
    --darkgray: gray;
    --headingbold: 800;
    --semibold: 600
}

.section.container-fluid,
.exclusive-box,
.arrival-section,
.referral-section {
    padding-left: 10px;
    padding-right: 10px;
}

.bg-size-adjust {
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.rotate-image {
    transform: rotate(180deg);
}

.fs-14 {
    font-size: 14px;
}

.white-font {
    color: #FFFFFF;

    a {
        color: #FFFFFF;
    }
}

.mt--1 {
    margin-top: -1px;
}

.img-text-area {
    width: 60%;
}

.bg-custom {
    background-color: #000000;
}

.pt-70 {
    padding-top: 70px;
}

.weight-500 {
    font-weight: 500;
}

.weight-600 {
    font-weight: 600;
}

.weight-700 {
    font-weight: 700;
}

.text-decoration-none {
    a {
        text-decoration: none;
    }
}

.terms-text {
    padding-top: 30px;
    padding-bottom: 30px;
}

section.sections {
    padding-bottom: 10px;
}

.boldhHeadingH5 {
    font-size: 18px;
    font-weight: var(--headingbold);
    padding: 30px 0px 15px 0px;
    color: #000;
}
.boldhHeadingH5_topHeading {
    font-size: 18px;
    font-weight: var(--headingbold);
    padding: 20px 0px 20px 0px;
    color: #000;
}

h5.boldhHeadingH5_topHeading.title {
    font-size: 18px;
}
h5.boldhHeadingH5.title {
    font-size: 18px;
}

.bg-gray {
    background-color: var(--lightgray);

}

.linerGradientRedBlack {
    background-image: linear-gradient(180deg, #000, #410000);
    color: #fff;
}

.paddingGap-right {
    padding-right: 10px;
}

.paddingGap-left {
    padding-left: 10px;
}

.footerEndSp {
    margin-bottom: 65px !important;
}

.comman-button {
    background-color: #DA2128;
    border: 1px solid #DA2128;
    border-radius: 4px;
    padding: 5px 20px;
    font-size: 12px;
    color: #fff;


    &:hover {
        background-color: #6B1111;
        border: 1px solid #6B1111;
    }
}

.hidden {
    visibility: hidden;
}

.faq-row {
    padding: 20px 0px;
}

.accordion-button {
    background-color: transparent !important;
    box-shadow: none !important;
}

.accordion-item {
    border: 0px;
    border-bottom: 1px solid #dee2e6 !important;

    .accordion-collapse {
        .MuiCollapse-wrapper {
            padding-bottom: 20px;
        }
    }
}


.backpageSection {
    img {
        width: 12px;
        height: 24px;
        cursor: pointer;
    }

    .backarrow_white {
        width: 14px;
        height: auto
    }

}

html,
body {
    margin: 0px;
    padding: 0px;
}

.plr-15 {
    padding-left: 1px;
    padding-right: 15px;
}

input {
    box-sizing: border-box;
    outline: 0;

    &:focus {
        border: solid #C1C1C1 1px;
        border: 1px dashed #2172dA;
    }

    &:focus-visible {
        border: solid #C1C1C1 1px;
        border: 1px solid #2172dA;
    }
}


.flag-btn {
    button {
        border-radius: 4px 0px 0px 4px;
        border: solid #C1C1C1 1px;
        border-right: 0px;
    }
}

.react-multi-carousel-list {

    // .react-multi-carousel-item {
    //     &:first-child {
    //         padding-left: 0px;
    //     }
    //     &:nth-child(2) {
    //         padding-right: 0px;
    //     }
    // }

    .image-box {
        border-radius: 4px;

        .img-tag {
            img {
                border-radius: 4px 4px 0px 0px;
            }
        }
    }
}

.coupon-card {
    .Reward-section {

        .image-box {
            height: 100%;

            .points {
                font-size: 12px;

                .pointBold {
                    font-weight: bold;
                    font-size: 14px;
                }

                .greenfree {
                    color: green;
                    font-weight: bold;
                }

                .freebox {
                    border: 1px solid var(--lightgray);
                    background-color: rgb(168, 200, 168);
                    padding: 2px 5px;
                    border-radius: 15px;
                }
            }

            .img-box-text {
                padding: 5px;
            }
        }
    }

    .reward {

        .points {
            color: #717171;
        }

        .pointBold {
            font-weight: bold;
            color: #000;
            font-size: 18px;
        }

        .reward-sec-col {
            width: 50%;
            padding: 5px;

            .date {
                font-size: 12px
            }

            .image-box {
                height: 100%;

                .points {
                    font-size: 12px;

                    .pointBold {
                        font-weight: bold;
                        font-size: 14px;
                    }

                    .greenfree {
                        color: green;
                        font-weight: bold;
                    }

                    .store_channel {
                        button {

                            font-size: 10px;
                        }
                    }

                    .freebox {
                        border: 1px solid var(--lightgray);
                        background-color: rgb(168, 200, 168);
                        padding: 2px 5px;
                        border-radius: 15px;
                    }
                }

                .img-box-text {
                    padding: 0px 10px 10px;
                    border: 1px solid var(--lightgray);
                    border-radius: 0px 0px 4px 4px;
                    max-height: 100px;
                    overflow: auto;    
                    .reward-text {
                        padding-top: 10px;
                        padding-bottom: 0px;
                        font-size: 14px;
                    }
                }

                .date {
                    // color: $darkgray;
                    font-size: 13px;
                    padding-top: 10px;
                }
            }
        }

        .enoughSec {

            .img-tag {
                background-color: #000;

                img {
                    opacity: .7;
                }
            }

        }

        .boldhHeadingH5 {
            font-size: 14px;
            margin: 0;
            padding-bottom: 0px;
        }

        .reward_button_store button {
            border-radius: 12px;
            border: 0.5px solid #C1C1C1;
            padding: 0px 10px;
            font-size: 13px;
            margin-right: 5px;
            margin-left: 2px;
            margin-top: 5px;
        }

        .tier {
            padding: 10px 0px;
        }

        .store_channel button {
            font-size: 10px;
        }

        .highest {
            button {
                color: #2172DA;
                background-color: #2172da47;
                border: 0.5px solid #2172da47;
            }
        }

        .img-tag {
            position: relative;

            img {
                border-radius: 4px 4px 0px 0px;
            }

            button {
                font-size: 10px;
                position: absolute;
                top: 5px;
                left: 15px;
                background-color: #4B4B4B;
                border: 1px solid #4B4B4B;
                color: #fff;
            }

            .enough_bal {
                button {
                    top: 70%;
                    font-size: 8px;
                    background-color: #fff;
                    color: #707070;
                }
            }
        }

        .couponShow {
            // padding-top: 20px;
            cursor: pointer;

            .coupon {
                padding: 12px;
                border-radius: 4px;
                background-color: #DA2128;
                color: #FFF;
                font-size: 10px;

            }

            .coupon:hover {
                background-color: #6B1111
            }

        }

    }
}

.samelayoutpage {
    h3.heading {
        font-weight: bold;
        font-size: 14px;
        padding: 20px 0;
    }

    p {
        font-size: 12px
    }
}

.faq-row {
    .accordion {
        --bs-accordion-btn-icon: url("data: image/svg+xml;base64, PHN2ZyBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLW1pdGVybGltaXQ9IjIiIHZpZXdCb3g9IjAgMCAyNCAyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJtMTEgMTFoLTcuMjVjLS40MTQgMC0uNzUuMzM2LS43NS43NXMuMzM2Ljc1Ljc1Ljc1aDcuMjV2Ny4yNWMwIC40MTQuMzM2Ljc1Ljc1Ljc1cy43NS0uMzM2Ljc1LS43NXYtNy4yNWg3LjI1Yy40MTQgMCAuNzUtLjMzNi43NS0uNzVzLS4zMzYtLjc1LS43NS0uNzVoLTcuMjV2LTcuMjVjMC0uNDE0LS4zMzYtLjc1LS43NS0uNzVzLS43NS4zMzYtLjc1Ljc1eiIgZmlsbC1ydWxlPSJub256ZXJvIi8+PC9zdmc+");
        --bs-accordion-btn-active-icon: url("data: image/svg+xml;base64, PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0wIDEydjFoMjN2LTFoLTIzeiIvPjwvc3ZnPg==");
    }

    .accordion-button {
        font-weight: bold;
        font-size: 14px
    }

    .accordion-button:focus {
        box-shadow: none;
        border: none;
    }

    .accordion-button {
        background: none;
        color: #000 !important;
        padding-left: 5px;
        font-weight: 700;
        font-size: 16px;
    }

    .accordion-body {
        padding: 0 5px;
        font-size: 14px;

        p {
            font-size: 16px;
            color: #555555;
        }
    }
}

.nav_heading {
    font-size: 22px;
    font-weight: var(--headingbold);
    padding: 20px 0;
    color: #000;
}

h1 {
    padding: 10px 0px;
}

.active_footer {
    p {
        color: #000 !important;
    }

    img {
        filter: brightness(0) saturate(0%);
    }
}