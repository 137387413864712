.notification-details{
.note-head{
    font-size: 18px !important;
    padding: 39px 0px 12px 0px !important;
    font-weight: bold;
}
.text-details{
    font-size: 14px !important;
}
.cross{
    text-align: end;
}
.profile-fields1{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
   .eventbutton{
      font-size: 18px;
      height: 50px;
      background-color: #000000;
      border: 1px solid #000000;
      border-radius: 4px;
      color: #ffffff;
      width: 100%;
  }
 .eventbutton:hover{
    background-color: #717171;
    border: 1px solid #717171;
  }
  }
  .bottomheight{
    height: 80px;
  }
}