* {
    margin: 0;
    padding: 0;
    text-decoration: none;
}

$color: #707070;
.red-dot{
    content: "";
  position: relative;
//   right: 5px; 
left: 72%;
  width: 5px;
  height: 5px;
  background-color: red;
  border-radius: 50%;
  @media (min-width: 700px) {
    left: 60%;
  }
}
.container {
    margin: 0 auto
}

.footer {
    border-top: 1px solid #D5D5D5;
    border-bottom: 1px solid #D5D5D5;
    font-family: sans-serif;
    position: fixed;
    bottom: 0;
    background: #fff;
    color: $color;
    width: 100%;
    z-index: 2;

    .footer-item {
        text-align: center;
        cursor: pointer;

        a {
            padding: 10px 0px;
            display: block;
            text-decoration: none;
        }

        i {
            font-size: 20px;
            color: $color;

        }

        p {
            color: $color;
            font-size: 14px;
        }
    }

    .footer-item:hover>* {
        color: black !important;
    }
    .active_footer {
        img {
            filter: brightness(0) saturate(0%);
        }

        p {
            color: #000;
            font-weight: 500;
        }
    }
}
.nav-max-content{
    width: max-content;
    @media (min-width: 470px ) {
        width: auto;
    }
}
.nav-max-content_home{
    width: max-content;
    @media (min-width: 400px ) {
        width: auto;
    }
}