.orderdetils {

    span,
    p {
        font-size: 14px;
    }

    .bold-dark {
        font-weight: bold;
    }

    .heading-gray {
        color: #707070;
    }

    .personal-detail {
        padding: 20px 0;

        button {
            border: 1px solid black;
            border-radius: 4px;
            font-size: 18px;
            color: black;

            &:hover {
                background-color: black;
                color: white;
            }
        }

        .modal {
            text-align: center;
            font-size: 22px;

            .modal-dialog {
                padding: 35px;
            }

            h5 {
                font-size: 22px;
            }

            .firsth5 {
                color: black;
                font-weight: var(--semibold);
            }

            .secondh5 {
                color: #707070;
                font-weight: 100
            }

            a {
                font-weight: 100
            }
        }
    }

    .order-section {
        padding: 20px 0;

        .product-list {
            padding: 15px 0px 0px 0px
        }
    }



}