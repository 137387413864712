.signup-container {
    overflow-x: hidden;
    margin: auto;
    width: 90%;
    align-items: center;
    justify-content: center;

    label {
        padding: 10px 0px 4px 0px;
        font-size: 14px;
        color: #0D0D0D;
    }

    .ReactFlagsSelect-module_filterBox__3m8EU input {
        /* Your custom styles here */
        margin: 0px 0px;
        /* Add any other styles as needed */
    }

    .ReactFlagsSelect-module_selectOptionsWithSearch__1W03w {
        width: 90vw;
        max-height: 200px;
    }

    .ReactFlagsSelect-module_selectBtn__19wW7 {
        height: 50px
    }

    .ReactFlagsSelect-module_filterBox__3m8EU {
        padding-top: 0px;
    }

    .ReactFlagsSelect-module_selectOptions__3LNBJ {
        margin-top: 0px;
    }

    input {
        width: 100%;
        height: 50px;
        padding: 15px 10px;
        font-size: 14px;
        color: #000000;
        border: solid #C1C1C1 1px;
        border-radius: 4px;
    }

    ::placeholder {
        font-size: 14px;
        color: #ADADAD;
    }

    select {
        width: 100%;
        height: 50px;
        padding: 15px 10px;
        font-size: 14px;
        color: #000000;
        border: solid #C1C1C1 1px;
        border-radius: 4px;
    }

    .error-message {
        font-size: 12px;
        color: #CC0000;
    }

    .mobile {
        display: flex;

        select {
            width: 30%;
            border-radius: 4px 0px 0px 4px;
        }

        input {
            width: 100%;
            border-radius: 0px 4px 4px 0px;
            // border-left: 0px;
        }
    }

    .moblabel {
        display: flex;
        justify-content: space-between;

        label {
            text-align: left;
        }

        .labelsub {
            padding: 10px 0px 4px 0px;
            font-size: 12px;
            text-align: right;
            color: #707070;
        }
    }

    #password {
        font-size: 50px;
    }

    .eyeicon {
        position: absolute;
        right: 35px;
        top: 395px;
        cursor: pointer;
    }

    .checkbox {
        font-size: 12px;
        color: #000;
        margin-top: 15px;

        .checkbox1 {
            display: flex;
        }

        .checktext {
            margin-left: 6px;
        }

    }

    .signupbutton {

        width: 100%;
        margin-top: 23px;

        button {
            font-size: 18px;
            height: 50px;
            background-color: #000000;
            border: 1px solid #000000;
            border-radius: 4px;
            color: #ffffff;
            width: 100%;
        }

        button:hover {
            background-color: #717171;
            border: 1px solid #717171;
        }
    }

    .forgot-label {
        font-size: 14px;
        color: #717171;
    }

    .alternative {
        text-align: center;
        margin-top: 10px;
        font-size: 14px;
    }

    .signin {
        font-weight: bold;
        text-decoration: underline;
        color: #000000;
    }
}
// input, select, textarea {
//     font-size: 14px;
//   }