.earnprogress {
  .img-section {
    width: 100%;

    img {
      border: 1px solid #ccc;
    }
  }

  .challenger-box {
    h5 {
      font-size: 18px;
    }

    .progress-earn {
      border: 1px solid #EBEBEB;
      margin-bottom: 5px;
      height: 5.16px;
      // background-color: #EBEBEB;
      margin-top: 5px;


      .progress-bar-earn{
        background: #00AD2F;
        // background: #EBEBEB;
    }
    }

    .Ponit_status {
      p {
        font-size: 10px;
      }
    }

    .challenger-details {
      .pointBold {
        font-weight: var(--semibold);
      }
    }

    .howWork {
      padding: 20px 0;
      .title {
        font-weight: bold;
        font-size: 14px;
      }

      .description {
        font-size: 14px;
        color: #707070;
      }
    }
  }
}
