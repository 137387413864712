.earn {
    .earn-pointRange {
        border-radius: 0 0 15px 15px;
        color: #fff;

        .points {
            font-size: 12px;
        }

        .heading_earn {
            font-size: 22px;
            font-weight: bold;
            text-align: center;
            padding-top: 20px;
            padding-bottom: 20px;
        }

        .pointBold {
            font-weight: bold;
            font-size: 18px;
        }

        .boldhHeadingH5 {
            color: #fff;
        }
    }

    .boldhHeadingH5 {
        text-align: center;
        padding-top: 20px;
        padding-bottom: 20px;
        margin: 0;
    }

    .title {
        text-align: left;
        padding-bottom: 0px;
        font-size: 18px !important;
    }

    .tier {
        display: flex;
        justify-content: space-between;

        .nextTier {
            font-weight: var(--regular) !important;
            // color: #717171;
        }
    }

    .Tier_graySection {
        background-image: linear-gradient(360deg, #000, #710202);
        color: #fff;
        border-radius: 0px 0px 4px 4px;

        .tier {
            display: flex;
            justify-content: space-between;
            width: 75%;
            margin: 0 auto;
            padding: 15px 0;

            .nextTier {
                font-weight: var(--regular);
                color: #717171;
            }

            .points {
                font-size: 12px;
            }

            h5 {
                font-size: 19px;
                font-weight: bold;
                margin-bottom: 0;
            }

            .tier-col {
                text-align: center;
                margin: 2px;
            }

            .borderColRight {
                border-right: 1px solid #fff;
            }
        }

        .nextTierText {
            color: #fff;
            padding-bottom: 15px;
        }
    }

    .progress {
        height: 5.16px;
        background-color:var(--darkgray);
        margin-top: 5px;
        margin-bottom: 10px;

        .pointProgressBar {
            background: #fff;
        }
        .progress-bar{
            background-color: #00AD2F;
        }
    }

    .nextTierText {
        font-size: 9px;
    }

    .earn-benefit {
        .boldhHeadingH5 {
            font-size: 14px !important;
            text-align: left;
            font-weight: var(--headingbold);
            padding-bottom: 10px;
        }

        .Tier_blackSection {
            background-color: #000;
            border-radius: 4px 4px 0px 0px;

            h5 {
                color: #fff;
                text-align: center;
                padding: 10px 0 !important;
            }
        }
    }

    .earn-more {
        .boldhHeadingH5 {
            text-align: left;
            font-size: 14px !important;
            padding-bottom: 10px;
        }

        .challenge_row {
            border: 0.5px solid #c1c1c1;
            border-radius: 4px;
            margin-bottom: 15px;
            background-color: #F8F8F8;
        }

        .img-section {
            img {
                width: 100%;
                height: 100%;
                border-radius: 4px 0px 0px 4px;
            }
        }

        .getPoints {
            padding: 10px 10px;

            .challengeName {
                font-size: 12px;
                padding-bottom: 10px;
            }

            .challenge-points {
                font-weight: 600;
            }

            .Ponit_status {
                p {
                    font-size: 8px;
                }
            }

            .claimed_btn {
                font-size: 12px;
                color: #00AD2F;
                border-radius: 4px;
                border: 0px solid #9e1414;
                padding: 5px 12px;
                background-color:#d3ffd8;
            }
            .get_points_btn{
                font-size: 12px;
                color: white;
                border-radius: 4px;
                border: 0px solid black;
                padding: 5px 18px;
                background-color:#959595;
                @media (max-width:"410px") {
                    padding: 5px 12px;
                }
            }

            .get_points_btn:hover {
                background-color: #959595;
                border: 1px solid #959595;
            }

            .tier_points_status_green {
                color: #00ad2f;
            }
            .tier_points_status_gray {
                color: #707070;
            }

            .progress {
                border: 1px solid #EBEBEB;
                margin-bottom: 5px;
            }
        }
    }

    .ScanEarn {
        .cool-section {
            border-radius: 4px;
        }

        h3 {
            font-size: 18px;
            font-weight: bold;
        }

        p {
            font-size: 12px;
            font-weight: 400;
        }

        button {
            font-size: 12px;
            font-weight: 400;
        }
    }

    .getPointPopup {
        text-align: center;

        .modal-dialog {
            padding: 30px;
        }

        .modal-content {
            border: 4px;
        }

        .modal-header,
        .modal-footer {
            border: none;
        }

        h5,
        p {
            font-weight: bold;
            font-size: 22px;
            color: #707070;
        }

        p {
            font-weight: var(--regular);
        }
    }
}

.earnPageModal{
    .modal {
        $modal-fade-transform: translate(-100px 100px);
        text-align: center;

        .earmModalImg {
            width: 80px;

        }

        .qr-code-digit {
            font-size: 16px;
            padding: 5px 0px 20px 0px;
        }

        .qr-code-time {
            font-size: 10px;
            color: #717171;
        }

        .modal-footer {
            justify-content: space-between;
            padding: 5px 0;
            background: #bcbcbc;

            img {
                width: 47px;
                margin-top: -30px;
                z-index: 10;
            }

            h6 {
                font-size: 14px;
                color: #000;
                padding-left: 15px;
                font-weight: 100;
            }
        }
    }

    .modal.fade.in .modal-dialog {
        padding: 10px;
    }

}
.loader{
    text-align: center;
    padding-top: 45vh;
}
.earn-carousals{
.carousel-indicators {
    position: relative;
    padding-top: 20px;
    bottom: 10px; 
    margin-bottom: 0px;
}
  
  .carousel-indicators button {
    border: 1px solid black;
    width: 5px; 
    height: 5px; 
    border-radius: 50%; 
    background-color: #fff; 
  }
  
  .carousel-indicators button.active {
    background-color: grey; 
  }
}
.earn-dots{
    width:8px;
    height: 8px;
    border-radius: 50%;
    border: 1px solid black;
    background-color: #fff;
    margin: 3px;
}
.earnbanner-active{
    background-color: #808080;
}